import React from "react"

import Back from "../back/Back"
// import AboutCard from "./AboutCard"

const Feedbacks = () => {
  return (
    <>
      <Back title='Feedbacks' />
      {/* <AboutCard /> */}
    </>
  )
}

export default Feedbacks
