import React, { useEffect, useState } from "react";
import './profile-image.css';
import { GetFirstNameInitial, GetLastNameInitial } from "../../../services/common-service";
import firebase from "firebase"; 

const TopDonersCard = ({ campaginId , type }) => {
    
    const [campaginDonationsData, setCampaginDonationsData] = useState([]);
    const refCampaginDonationTable = firebase.firestore().collection('CampaginDonationTable');
  useEffect(() => {
    BindCampaginDonationsData(campaginId);
  }, []);

  
  const BindCampaginDonationsData = (campaginId) => {
    if (campaginId != null && type == 'view-details') { 
      refCampaginDonationTable.where("campaignId", "==", campaginId) 
        .get()
        .then((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push(doc.data());
          });
          setCampaginDonationsData(items);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    } else {
        // if (campaginId == null && type != 'view-details') { 
        //     refCampaginDonationTable
        //       .get()
        //       .then((querySnapshot) => {
        //         const items = [];
        //         querySnapshot.forEach((doc) => {
        //           items.push(doc.data());
        //         });
        //         setCampaginDonationsData(items);
        //       })
        //       .catch((error) => {
        //         console.log("Error getting documents: ", error);
        //       });
        //   } 
    }
  };

    return (
        <>
            {campaginDonationsData && 
            campaginDonationsData?.slice(0, 100).map((user) => (
                <div>
                    <div className='user-avatar'>
                        <div className='items shadow'>
                            <div className='content flex'>
                                <div className='left text'>
                                    <div className='details'>
                                        <div className='box'>
                                            <div className="user-profile-image">
                                                {GetFirstNameInitial(user.displayName)}
                                                {GetLastNameInitial(user.displayName)}
                                            </div>
                                            <div className='text'>
                                                <h4>{user.displayName}</h4>  
 
                                                <span className='amount' >  ₹ {user.amount}   </span> <span className='date'>  <i className='fa fa-calendar-alt'></i> { " "+ user?.createdAt.toDate().toString().substring(0, 21)} 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            ))}</>
    )
};
export default TopDonersCard;