import React, { useEffect, useState } from "react"
import firebase from "../../firebase";
import "./admin-campagin-list.css"
import { Link } from "react-router-dom";
import { GetUserAdminData } from "../../services/user-service";
import { GetAllCampagins, GetCampaginDataByStatus, GetCampaginDataByTitle } from "../../services/common-service";

const AdminCampaginList = () => {
  const [campaginData, setCampaginData] = useState([]);
  const isAdminUser = GetUserAdminData('Admin');
  const refcampaginTable = firebase.firestore().collection('campaginTable');
  const refCampaginImageTable = firebase.firestore().collection('CampaginImageTable');

  useEffect(() => {

    const fetchData = async () => {
      const campgainData  = await GetAllCampagins(refcampaginTable, refCampaginImageTable);
      setCampaginData(campgainData);
    };

    fetchData();
 
  }, []);
  return (
    <>
      {isAdminUser && campaginData &&
       <div className='app-container'>
        <table>
          <thead>
            <tr>
              <th>Campaigner Name</th>
              <th>Country</th>
              <th>Currency</th>
              <th>Goal Amount</th>
              <th>Category</th>
              <th>Location</th>
              <th>End Date</th>
              {/* <th>Your Story</th> */}
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {campaginData.map((campaginItem) => (
              <tr>
                <td>{campaginItem.campaignerName}</td>
                <td>{campaginItem.title}</td>
                <td>{campaginItem.country}</td>
                <td>{campaginItem.currency}</td>
                <td>{campaginItem.goalAmount}</td>
                <td>{campaginItem.category}</td>
                <td>{campaginItem.location}</td>
                <td>{campaginItem.endDate}</td>
                {/* <td>{campaginItem.yourStory.substring(0, 50) + ".."}</td> */}
                <td>{campaginItem.type}</td>
                <td>{campaginItem.status}</td>

                <td>
                  <Link to={`/admin-view-details/${campaginItem.title}`}>
                    <button >View</button>
                  </Link>
                  <Link to={`/campaign-form/${campaginItem.title}`}>
                    <button >Edit</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br/>
        </div>

      }
    </>
  )
}

export default AdminCampaginList
