import React from "react"
import Heading from "../heading/Heading"


const HowItWorksCard = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className='app-container'>
          <div className='app-row'>
            <Heading subtitle='Step 1. Start a free fundraiser' title='Start a free fundraiser by filling in all the relevant details' />
          </div>

          <div className='app-row'>
            <Heading subtitle='Step 2. Share Your Fundraiser' title='Share your fundraiser with friends, family and strangers to raise funds quickly' />
          </div>

          <div className='app-row'>
            <Heading subtitle='Step 3. Withdraw All Donations' title='Withdraw all the money you receive at any point in your fundraising journey' />
            
          </div>
          <br/>
        </div>
      </section>
    
    </>
  )
}

export default HowItWorksCard
