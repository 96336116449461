import firebase from "firebase";
import React, { useEffect, useState } from "react";
import axios from "axios";


const EmailSender = () => {
 
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [htmlEmailContent, setHtmlEmailContent] = useState("");
  
  useEffect(() => {
    // Fetch HTML data from a file or API
    fetch('http://localhost:3000/email-templates/template1.html') // Adjust the path or URL to the HTML file
      .then((response) => response.text())
      .then((html) => setHtmlEmailContent(html))
      .catch((error) => console.error("Error fetching HTML:", error));
  }, []);

  const sendEmail = async () => {
    const apiKey = "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"; // Replace with your API key

    const emailPayload = {  
      sender: { name: "AJ Smile Foundation", email: "info@aj-smile-foundation.com" },
      to: [{ name :"javid test", email: to }],
      subject: subject,
      htmlContent: htmlEmailContent.replace('{donarName}', to)
    };

    try {
      const response = await axios.post(
        "https://api.brevo.com/v3/smtp/email",
        emailPayload,
        {
          headers: {
            "content-type": "application/json",
            "accept": "application/json",
            "api-key": apiKey,
          },
        }
      );
      console.log("Email sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

//   const sendEmail = async () => {
//     try {
//       await firebase.firestore().collection("emails").add({
//         to,
//         subject,
//         message,
//       });

//       admin.firestore().collection('mail').add({
//         to: to,
//         message: {
//           subject: 'Hello from Firebase!',
//           html: 'This is an <code>HTML</code> email body.',
//         },
//       })

//       alert("Email request sent successfully!");
//     } catch (error) {
//       console.error("Error sending email:", error);
//       alert("Failed to send email.");
//     }
//   };

  return (
    <div>
      <h1>Send Email</h1>
      <input
        type="email"
        placeholder="Recipient Email"
        value={to}
        onChange={(e) => setTo(e.target.value)}
      />
      <input
        type="text"
        placeholder="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <textarea
        placeholder="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      <button onClick={sendEmail}>Send Email</button>
    </div>
  );
};

export default EmailSender;
