import React from 'react';

const Step7 = ({ status }) => {
  return (
    <>
      <section tabindex="1" id="steps">
        <div class="stepper-wrapper">
          <div class="stepper-item completed">
            <div class="step-counter">1</div>
          </div>
          <div class="stepper-item completed">
            <div class="step-counter">2</div>
          </div>
          <div class="stepper-item completed">
            <div class="step-counter">3</div>
          </div>
          <div class="stepper-item completed">
            <div class="step-counter">4</div>
          </div>
          <div class="stepper-item completed">
            <div class="step-counter">5</div>
          </div>
          <div class="stepper-item completed">
            <div class="step-counter">6</div>
          </div>
        </div>

        <h2>Step 7: Status </h2>
        <br />
        <br />

        {status === 'success' && (<div class="app-row app-container"><div  >
          <h3><strong>Success!</strong> You have successfully saved all the changes</h3>
        </div></div>)}
        {status === 'update' && (<div class="app-row app-container"><div  >
          <h3><strong>Success!</strong> You have updated successfully</h3>
        </div></div>)}
        {status === 'error' && (
          <div class="app-row app-container"><div  >
            <h3><strong>Danger!</strong> You have not saved all the changes</h3>
          </div></div>
        )}
      </section>
    </>
  );
};

export default Step7;