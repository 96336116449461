
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react"
import firebase from "firebase";
import { UpdateCampaginAmountData } from "../../services/common-service";
import CommonModal from "../common/common-model";
import { CampaignProgressBar2 } from "./campaign-progress-bar/campaign-progress-bar";
import { Button, Form } from "react-bootstrap";
import { SendEmailMessage } from "../../services/email-service";

const DonationPopup = ({ campaginData, isVisibleModal, onHandleCloseClick }) => {
    const [errors, setErrors] = useState({});
    const [htmlEmailContent, setHtmlEmailContent] = useState("");
    let URLPath = window.location.origin;
    const refCampaginDonationTable = firebase.firestore().collection('CampaginDonationTable');
    const initialRequestDonationData = {
        id: uuidv4(),
        campaignId: '',
        name: '',
        email: '',
        phone: '',
        WhatsAppNumber: '',
        displayName: '',
        amount: 1000,
        IsAnonymousDonation: false,
        location: '',
        endDate: '',
        type: '',
        campaignerName: '',
        paymentMethod:'Razorpay payment',
        campaignerEmailId: '',
        createdAt: '',
        lastUpdate: '',
        isActive: true,
        status: 'Submit',
        razorpay_payment_id:''
    };
    //setCampaginData(campaginDataByIdData);
    const [campaginDonationData, setCampaginDonationData] = useState(initialRequestDonationData);
    const [campaginDonationsCount, setCampaginDonationsCount] = useState([]);
    const onAmountChange = (value) => {
        const target = { target: { name: 'amount', value: value } };
        handleDonationChange(target);
    };

    const handleDonationChange = (event) => {
        const { name, value } = event.target;
        setCampaginDonationData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleMakeAnonymousDonationChange = (e) => {
        if (e.target.checked) {
            campaginDonationData.displayName = "Anonymous";
        } else {
            campaginDonationData.displayName = "";
        }
        setCampaginDonationData({ ...campaginDonationData });
    };

  

    useEffect(() => {

        refCampaginDonationTable.get().then((querySnapshot) => {
            setCampaginDonationsCount(querySnapshot.size);
        });
        setCampaginDonationData(initialRequestDonationData);

         // Fetch HTML data from a file or API
         fetch(URLPath +'/email-templates/template1.html') // Adjust the path or URL to the HTML file
         .then((response) => response.text())
         .then((html) => setHtmlEmailContent(html))
         .catch((error) => console.error("Error fetching HTML:", error));

    }, []);

    const handleDonationSubmit = (razorpay_payment_id) => {

        campaginDonationData.campaignId = campaginData.id;
        campaginDonationData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        campaginDonationData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
        campaginDonationData.razorpay_payment_id = razorpay_payment_id;
        refCampaginDonationTable.doc(campaginDonationData.id)
            .set(campaginDonationData)
            .then(() => {
                UpdateCampaginAmountData(campaginData, campaginDonationData.amount, campaginDonationsCount ?? 0);
                setCampaginDonationData(initialRequestDonationData);
                onHandleCloseClick();
            })
            .catch((err) => {
                console.error(err);
            });

    }

    const validateForm = (data) => {
        const newErrors = [];
        if (!data.name.trim()) {
            newErrors.name = 'Name is required';
        } else if (data.name.length < 4) {
            newErrors.name = 'Name must be at least 4 characters long';
        } 
        if (!data.email.trim()) {
            newErrors.email = 'Email is required';
        } 
        if (!data.phone) {
            newErrors.phone = 'Phone is required';
        } else if (data.phone.length < 11) {
            newErrors.name = 'Phone Number must be 10 digit long';
        }
         if (!data.whatsAppNumber) {
            newErrors.whatsAppNumber = 'WhatsApp Number is required';
        }  if (!data.displayName.trim()) {
            newErrors.displayName = 'Display Name is required';
        }

        setErrors(newErrors);
        return newErrors;
    };

    const loadRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {

        campaginDonationData.campaignId = campaginData.id;
        campaginDonationData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        campaginDonationData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
        // SendEmailMessage(campaginData, campaginDonationData, htmlEmailContent);

        // return;
      

        const newErrors = validateForm(campaginDonationData);
        setErrors(newErrors);
       
        if (Object.keys(newErrors).length === 0) {

            const isLoaded = await loadRazorpay();

            if (!isLoaded) {
                alert('Razorpay SDK failed to load. Are you online?');
                return;
            }

            const options = {
                key: 'rzp_test_N7DZUMI6Yznw0e', // Replace with your Razorpay key ID
                amount: campaginDonationData?.amount, // Payment amount in the smallest currency unit (e.g., paise for INR)
                currency: 'INR',
                name: 'AJ Smile Foundation',
                description: 'Payment for services',
                image: 'https://your_logo_url.com', // Optional: replace with your company logo
                handler: (response) => {
                    console.log(response);
                   // alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
                   handleDonationSubmit(response.razorpay_payment_id);
                   SendEmailMessage(campaginData, campaginDonationData, htmlEmailContent);
                },
                prefill: {
                    name: campaginDonationData?.name,  // Optional: Prefill user name
                    email: campaginDonationData?.email,  // Optional: Prefill user email
                    contact:campaginDonationData?.phone,  // Optional: Prefill user contact number
                },
                notes: {
                    address: 'Razorpay Corporate Office',
                },
                theme: {
                    color: '#F37254',  // Customize the color of the payment button
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
    };

    return (
        <>
            {campaginData?.id &&
                <CommonModal show={isVisibleModal} onHandleClick={handlePayment}
                    onHandleCloseClick={onHandleCloseClick}
                    variant="warning" >
                    {
                        <Form>
                            <CampaignProgressBar2 currentAmount={campaginData.amount ?? 0}
                                totalSupporters={campaginData.noOfSupporters ?? 0}
                                goalAmount={campaginData.goalAmount ?? 0} ></CampaignProgressBar2>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>₹ Amount</Form.Label>
                                <Form.Control type="number" name="amount" onChange={handleDonationChange}
                                    required value={campaginDonationData.amount}
                                    placeholder="Amount" />
                                <Button variant="warning" onClick={() => onAmountChange(250)}>₹ 250 </Button>
                                <Button variant="warning" onClick={() => onAmountChange(500)}>₹ 500 </Button>
                                <Button variant="warning" onClick={() => onAmountChange(1000)}>₹ 1000 </Button>
                                <Button variant="warning" onClick={() => onAmountChange(2500)} >₹ 2500 </Button>
                                <Button variant="warning" onClick={() => onAmountChange('')}>₹ Custom </Button>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" name="name"
                                    value={campaginDonationData.name}
                                    onChange={handleDonationChange} />

                                {errors.name && (
                                    <label className="error-message">
                                        {errors.name}
                                    </label>
                                )}

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" name="email" placeholder="name@example.com"
                                    value={campaginDonationData.email}
                                    onChange={handleDonationChange} />
                                {errors.email && (
                                    <label className="error-message">
                                        {errors.email}
                                    </label>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number" maxLength="12"
                                    name="phone"
                                    value={campaginDonationData.phone}
                                    onChange={handleDonationChange} placeholder="Phone Number" />
                                {errors.phone && (
                                    <label className="error-message">
                                        {errors.phone}
                                    </label>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>WhatsApp Number</Form.Label>
                                <Form.Control type="number" maxLength="14"
                                    name="whatsAppNumber"
                                    value={campaginDonationData.whatsAppNumber}
                                    onChange={handleDonationChange}
                                    placeholder="WhatsApp Number" />
                                {errors.whatsAppNumber && (
                                    <label className="error-message">
                                        {errors.whatsAppNumber}
                                    </label>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Display Name</Form.Label>
                                <Form.Control type="text" maxLength="14" placeholder="Display Name"
                                    name="displayName"
                                    value={campaginDonationData.displayName}
                                    onChange={handleDonationChange} />

                                {errors.displayName && (
                                    <label className="error-message">
                                        {errors.displayName}
                                    </label>
                                )}
                            </Form.Group>

                            <Form.Check // prettier-ignore
                                type="switch"
                                label="Make this an anonymous donation"
                                id="disabled-custom-switch"
                                onChange={handleMakeAnonymousDonationChange}
                            />
                            <br />
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <h4 > <Form.Label variant="success" >Total Amount : ₹ {campaginDonationData.amount}</Form.Label></h4>
                            </Form.Group>
                        </Form>
                    }
                </CommonModal>
            }
        </>
    )
}

export default DonationPopup