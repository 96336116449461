import React, { useState } from 'react';
import "./fileupload.css"
const Step4 = ({ nextStep, prevStep, handleChange, values }) => {

  const [errors, setErrors] = useState({});
  const handleSubmit = (e, nextStep, formData) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      nextStep();
    }
  };

  const validateForm = (data) => {
    const newErrors = [];
    if (!data.campaignerName.trim()) {
      newErrors.campaignerName = 'Campaigner Name is required';
    } else if (data.campaignerName.length < 3) {
      newErrors.campaignerName = 'Campaigner Name must be at least 3 char long';
    }
    if (!data.campaignerEmailId.trim()) {
      newErrors.campaignerEmailId = 'Campaigner EmailId is required';
    }
    if (!data.campaignerPhone.trim()) {
      newErrors.campaignerPhone = 'Campaigner Phone is required';
    }
    if (!data.campaignerIdProof.trim()) {
      newErrors.campaignerIdProof = 'Campaigner Id Proof is required';
    }

    setErrors(newErrors);
    return newErrors;
  };

  
  const handleChange1 = (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0];
    const allowedFileTypes = ["jpg", "png"];
    const newErrors = [];
    const fileExtension = selectedFile?.name?.split(".").at(-1);

    if (name == "campaignerIdProof") {
      if (!allowedFileTypes.includes(fileExtension)) {
        newErrors.campaignerIdProof = "File does not support. You must use .png or .jpg";
      }
      else if (selectedFile.size > 85000) {
        newErrors.campaignerIdProof = "Please upload a file smaller than 85 KB";
      }
    }  

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleChange(e);
    }
  };

  return (
    <div tabindex="1" id="steps">
      <div class="stepper-wrapper">
        <div class="stepper-item completed">
          <div class="step-counter">1</div>
        </div>
        <div class="stepper-item completed">
          <div class="step-counter">2</div>
        </div>
        <div class="stepper-item completed">
          <div class="step-counter">3</div>
        </div>
        <div class="stepper-item active">
          <div class="step-counter">4</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">5</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">6</div>
        </div>
      </div>

      <div class="app-container">
        <h2>Step 4: Campaigner Details </h2>
        <br />
        <br />
        <div class="app-row">
          <div class="col-75">
            <label>
              Name:
              <input
                type="text"
                name="campaignerName"
                value={values.campaignerName}
                onChange={handleChange}
              />
              {errors.campaignerName && (
                <label className="error-message">
                  {errors.campaignerName}
                </label>
              )}
            </label>
          </div>
        </div>
        <div class="app-row">
          <div class="col-75">
            <label>
              Email:
              <input
                type="email"
                name="campaignerEmailId"
                value={values.campaignerEmailId}
                onChange={handleChange}
              />
              {errors.campaignerEmailId && (
                <label className="error-message">
                  {errors.campaignerEmailId}
                </label>
              )}
            </label>
          </div>
        </div>
        <div class="app-row">
          <div class="col-75">
            <label>
              Phone:
              <input
                type="text"
                name="campaignerPhone"
                value={values.campaignerPhone}
                onChange={handleChange}
              />
              {errors.campaignerPhone && (
                <label className="error-message">
                  {errors.campaignerPhone}
                </label>
              )}
            </label>
          </div>
        </div>
        <div class="app-row">
          <div class="col-75">
            <label>
              Campaigner's ID Proof:
              <div class="drop-zone">

                {values.campaignerIdProof ? <img src={values.campaignerIdProof} className="image" alt="preview" /> :
                  <span class="drop-zone__prompt">Drop file here or click to upload</span>}

                <input
                  type="file"
                  name="campaignerIdProof"
                  accept="image/*"
                  class="drop-zone__input"
                  onChange={handleChange1}></input>
              </div>
              {errors.campaignerIdProof && (
                <label className="error-message">
                  {errors.campaignerIdProof}
                </label>
              )}
            </label>

          </div>
        </div>
        <br />
        <button className='primary-btn' onClick={prevStep}>Back</button>
        <button className='secoundry-btn' onClick={(e) => handleSubmit(e, nextStep, values)} >Next</button>
      </div>
    </div>
  );
};

export default Step4;