import React, { useState } from "react"
import Back from "../common/back/Back"
import "./contact.css"
import Focus from "../common/focus/focus";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [status, setStatus] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const map = 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1161.6413963795999!2d78.16463757122767!3d11.674533266601381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDQwJzI4LjEiTiA3OMKwMDknNTQuOCJF!5e0!3m2!1sen!2sin!4v1730279342091!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"';
  const refContactUsTable = firebase.firestore().collection('ContactUsTable');
  const [errors, setErrors] = useState({});
  const intialReq = {
    id: uuidv4(),
    name: '',
    email: '',
    subject: '',
    message: '',
    type:'ContactUs'
  };
  const [contactData, setContactData] = useState(intialReq);

  const handleCaptcha = (token) => {
    console.log("Captcha Value:", token);
    if (token) {
      setCaptchaToken(token);
      setCaptchaVerified(true);
    }
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm(contactData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {

      contactData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      contactData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
      refContactUsTable.doc(contactData.id)
        .set(contactData)
        .then(() => {
          setContactData(intialReq);
          setStatus('success');
          setCaptchaToken(null);
          setCaptchaVerified(false);
        })
        .catch((err) => {
          console.error(err);
        });

    }
  };

  const validateForm = (data) => {
    const newErrors = [];
    if (!data.name.trim()) {
      newErrors.name = 'Name is required';
    } else if (data.name.length < 4) {
      newErrors.name = 'Name must be at least 4 characters long';
    }
    if (!data.email.trim()) {
      newErrors.email = 'Email is required';
    }
    if (!data.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    if (!data.message.trim()) {
      newErrors.message = 'Message is required';
    }
    if (!captchaToken) {
      newErrors.captcha ="Please complete the CAPTCHA.";
      return;
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setContactData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <Focus>
        <Back title='Contact us' />
        <section className='contacts padding'>
          <div className='app-container shadow flexSB'>
            <div className='left app-row'>
              <iframe src={map}></iframe>
            </div>
            <div className='right app-row'>
              <h1>Contact us</h1>
              <p>We're open for any suggestion or just to have a chat</p>

              <div className='items grid2'>
                <div className='box'>
                  <h4>ADDRESS:</h4>
                  <p>266, East St, Hastampatty, Salem, TN, India </p>
                </div>
                <div className='box'>
                  <h4>EMAIL:</h4>
                  <p> infoajsmilefoundation@gmail.com</p>
                </div>
                <div className='box'>
                  <h4>PHONE:</h4>
                  <p> + 91-9791994147</p>
                </div>
                
              </div>

              <form action=''>

                {status === 'success' && (<div className="success"> 
                  <h5><strong>Success!</strong> You have send message successfully !!!</h5>
                </div>)}


                <div class="app-row ">
                  <input type='text' placeholder='Name'
                    name="name"
                    onChange={handleOnChange}
                    value={contactData.name} />
                  {errors.name && (
                    <label className="error-message">
                      {errors.name}
                    </label>
                  )}
                </div>
                <div class="app-row">
                  <input type='email' placeholder='Email'
                    name="email"
                    onChange={handleOnChange}
                    value={contactData.email} />
                  {errors.email && (
                    <label className="error-message">
                      {errors.email}
                    </label>
                  )}
                </div>
                <div class="app-row">
                  <input type='text' placeholder='Subject'
                    onChange={handleOnChange}
                    name="subject" value={contactData.subject} />
                  {errors.subject && (
                    <label className="error-message">
                      {errors.subject}
                    </label>
                  )}
                </div>
                <div class="app-row">
                  <textarea cols='30' rows='10'
                    name="message"
                    onChange={handleOnChange}
                    value={contactData.message}>
                  </textarea>
                  {errors.message && (
                    <label className="error-message">
                      {errors.message}
                    </label>
                  )}
                </div>
                <div class="app-row" style={{ marginTop: "10px" }}>
                  
          <ReCAPTCHA  
      
            sitekey="6LfyeogqAAAAABDrvi0fIVAW0txC4_OZ9uba1TJW" 
            onChange={handleCaptcha}
          />
           {errors.captcha && (
                    <label className="error-message">
                      {errors.captcha}
                    </label>
                  )}
        </div>
                <div class="app-row">
                  <button   
                  className={` ${!captchaVerified ? "disabled" : "primary-btn"}`}
                  disabled={!captchaVerified} 
                  onClick={handleSubmit}>SEND MESSAGE</button>
                </div>
              </form>


            </div>
          </div>
        </section>
      </Focus>
    </>
  )
}

export default ContactUs
