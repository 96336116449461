import React from "react"
import "./service.css"
import ServiceCard from "./service-card"
import Heading from "../common/heading/Heading"

const ServicePage1 = () => {
  return (
    <>
      <Heading  subtitle='Empowerment initiatives' title='Transforming lives through support and resources' />
      <section className='card padding'>     
        <div className='app-container grid2'>
          <ServiceCard />
        </div>
      </section>
    </>
  )
}

export default ServicePage1;
