import React from "react"
import TopDonersCard from "./top-doners-card"
import { BackHand } from "@mui/icons-material"
import Focus from "../../common/focus/focus"
import Back from "../../common/back/Back"
import { ViewCard } from "./view-card"
import Heading from "../../common/heading/Heading"

const TopDoners = () => {
  return (
    <>
      <Focus>
        <Back title='Top Doners' />
        <div className="app-container">
 
            <Heading subtitle='Top Doners' title='' />
            <ViewCard title='Top Donations'>
              <TopDonersCard />
            </ViewCard>
        
        </div>
      </Focus>
    </>

  )
}

export default TopDoners
