import React from "react"
import AboutCard from "../about/AboutCard"
import Hero from "./hero/Hero"
import DiscoverCard from "../discovers/discover-card"
import ReactHelmet from "../../services/react-helmet";
import ServicePage1 from "../service/service1";
 

const Home = () => {
  return (
    <>
        <ReactHelmet 
      title = 'Home'
      desc = 'Home'
      url = 'home'/> 
      <Hero />
      <AboutCard />
      <DiscoverCard />
      <ServicePage1 />
      {/* <Testimonal />
    <Hblog />
      <Hprice /> */}
    </>
  )
}

export default Home
