import React from "react"
 
import Back from "../common/back/Back"
import AboutCard from "./AboutCard"
import Focus from "../common/focus/focus"
import ReactHelmet from "../../services/react-helmet"
import Awrapper from "./Awrapper"

const About = () => {
  return (
    <>
      <ReactHelmet 
      title = 'About Us'
      desc = 'About Us'
      url = 'about'/>

      <Focus>
        <Back className='back2' title='About Us' />
        <AboutCard />
        <Awrapper />
      </Focus>
    </>

  )
}

export default About
