import { Button, ProgressBar } from 'react-bootstrap';
import "./campaign-progress-bar.css"
import { useEffect, useState } from 'react';
import { ActionControl } from '../actions/action-control';
import { ConvertNaNToDecimal, ConvertNaNToInt } from '../../../services/common-service';

const CampaignProgressBar = ({ sharedCount, currentAmount, goalAmount, totalSupporters }) => {
  const [percentage, setPercentage] = useState(0);
  let _totalSupporters = ConvertNaNToInt(totalSupporters);
  const formatNumber = (q) => {
    return ConvertNaNToDecimal(q).toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR'
    })
  }

  useEffect(() => {
    currentAmount = ConvertNaNToDecimal(currentAmount);
    goalAmount = ConvertNaNToDecimal(goalAmount);

    if (currentAmount >= 0 && goalAmount >= 0) {
      const resultValue = ConvertNaNToDecimal(Math.round(Number(currentAmount ?? 0) / Number(goalAmount ?? 0) * 100));
      setPercentage(resultValue);
    }
  }, []);
  return (
    <>
      <div className='progressBar'>
        <br />
        <ProgressBar striped now={percentage} variant="warning" label={`${percentage}%`} />

        <div className='details'>
          <span> INR</span>  {formatNumber(currentAmount ?? 0)} of <span> {formatNumber(goalAmount ?? 0)} Raised. </span><br/>
          {_totalSupporters ?? 0}  <span> Supporters </span> 
          {ConvertNaNToInt(sharedCount)} <span>  Shared  </span>
        </div>

      </div>
    </>
  )
}



// View Detail Card

const CampaignProgressBar1 = ({ currentAmount, goalAmount, status, totalSupporters, openInNewTab, 
  setVisibleModal, sharedCount }) => {

  const [percentage, setPercentage] = useState(0);
  let _totalSupporters = ConvertNaNToInt(totalSupporters);
  const formatNumber = (q) => {
    return ConvertNaNToDecimal(q).toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR'
    })
  }
  useEffect(() => {
    currentAmount = ConvertNaNToDecimal(currentAmount);
    goalAmount = ConvertNaNToDecimal(goalAmount);

    if (currentAmount >= 0 && goalAmount >= 0) {
      const resultValue = ConvertNaNToDecimal(Math.round(Number(currentAmount ?? 0) / Number(goalAmount ?? 0) * 100));
      setPercentage(resultValue);
    }
  }, []);


  return (
    <>
      <div className='progressBar'>
        <ProgressBar striped now={percentage} variant="warning" label={`${percentage}%`} />
        <div className='details'>
          <span> INR</span>  {formatNumber(currentAmount ?? 0)} of <span> {formatNumber(goalAmount ?? 0)} Raised.  Spread Smile <i class="fa fa-smile"></i> </span> 
          <div className='support-right'> {_totalSupporters ?? 0} <span>Supporters supported </span> 
          {ConvertNaNToInt(sharedCount)} <span>  Shared  </span> <i className='fa fa-user'></i>
          </div>
        </div>
      </div>
      <ActionControl status={status} openInNewTab={openInNewTab} setVisibleModal={setVisibleModal}></ActionControl>
    </>
  )
}

//  Donataion Popup
const CampaignProgressBar2 = ({ sharedCount, currentAmount, goalAmount, totalSupporters }) => {
  const [percentage, setPercentage] = useState(0);
  let _totalSupporters = ConvertNaNToInt(totalSupporters);
  const formatNumber = (q) => {
    return ConvertNaNToDecimal(q).toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR'
    })
  }

  useEffect(() => {
    currentAmount = ConvertNaNToDecimal(currentAmount);
    goalAmount = ConvertNaNToDecimal(goalAmount);

    if (currentAmount >= 0 && goalAmount >= 0) {
      const resultValue = ConvertNaNToDecimal(Math.round(Number(currentAmount ?? 0) / Number(goalAmount ?? 0) * 100));
      setPercentage(resultValue);
    }
  }, []);
  return (
    <>
      <div className='progressBar'>
       
        <ProgressBar striped now={percentage} variant="warning" label={`${percentage}%`} />

        <div className='details'>
          <span> INR</span>  {formatNumber(currentAmount ?? 0)} of <span> {formatNumber(goalAmount ?? 0)} Raised. </span>
          {/* {_totalSupporters ?? 0}  <span> Supporters </span> 
          {ConvertNaNToInt(sharedCount)} <span>  Shared  </span> */}

          <div className='support-right'> {_totalSupporters ?? 0} <span>Supporters </span>  {ConvertNaNToInt(sharedCount)} <span>  Shared  </span> <i className='fa fa-user'></i>
          </div>
          
        </div>
        <br />
      </div>
    </>
  )
}

export { CampaignProgressBar, CampaignProgressBar1, CampaignProgressBar2 };