import { Helmet } from "react-helmet";


const ReactHelmet = ({ title, desc, url, imageURL }) => {
  return (
    <Helmet>
      <title>{title} - AJ Smile Foundaion Trust</title>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta name="description" content={`Learn more about ${desc}`} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title} - AJ Smile Foundaion Trust`} />
      <meta property="og:description" content={`Learn more about ${title}`} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="AJ Smile Foundaion Trust" />
      <meta property="og:image" content={imageURL ?? 'https://aj-smile-foundation.com/images/bg4.jpeg'} />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="240" />
      <meta property="og:image:height" content="240" />
      <meta property="og:image:alt" content={title} />

    </Helmet>
  )
}

export default ReactHelmet;