import React from "react"

import "./../view-details/view-details.css"

const ViewCard = ({ title, children }) => {
    return (
        <>
            <div className='viewDetailCard'>
                <div className='items shadow'>
                    <div class='item content'>
                        <div class='sub-header'>
                            <div class="headerCls">
                                <h2> <i className='fa fa-user'></i>  {title}    </h2>
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

const ViewCard2 = ({ title, children }) => {
    return (
        <>
            <div className='viewDetailCard'>
                <div className='items shadow'>
                    <div class='item content'>
                        <div className='text'>
                            { title != 'Empty' && <div class='sub-header'>
                                <div class="headerCls">
                                    {title == 'Story' && <h2>  <i class="fa fa-flag "></i> Story</h2>}
                                    {title == 'Documents' && <div>
                                        <h2>
                                            <i class="fa fa-file"></i> Documents
                                            <img src="../../images/verified.png" height='50px'
                                                width='50px' alt="verified" />
                                        </h2>
                                    </div>}

                                </div>
                            </div>}
                            {title != 'Story' &&
                                <div className='details'>
                                    <div className='box'>
                                        {children}
                                    </div>
                                </div>}
                            {title == 'Story' &&  children }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ViewCard, ViewCard2 }

