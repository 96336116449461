 

export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: " MISSION",
    desc: "Our mission is to uplift and empower individuals who are deprived of basic human needs by providing them with access to education, healthcare, employment opportunities, and essential services. We strive to create an inclusive society where every individual, regardless of their background, has the resources and support to reach their full potential. Through sustainable interventions and community-driven initiatives, we are committed to building a future where everyone has the opportunity to live with dignity, independence, and hope for a better tomorrow.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: " VISION",
    desc: "Our vision is to create a society where every individual, regardless of socio-economic status, has the opportunity to lead a life of dignity, independence, and fulfillment. We aspire to build a world where poverty, inequality, and deprivation no longer hinder the dreams and potential of individuals. Through sustained efforts in education, healthcare, and livelihood support, we envision empowered communities that thrive on equal opportunities, fostering a future of growth, security, and shared prosperity for all.",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "100+",
    title: "DONORS",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "10+",
    title: "CAMPAINS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "10,000",
    title: "Funded",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "Smile Spread",
  },
]
export const logoPath = "../images/logo.jpg";

export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Introducing to Software Engineering",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by John Smith",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Enhancing Adobe Photoshop CC 2020 Skills",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Ram Gurung",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "HTML, CSS, and Javascript for Web Developers",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Saroj Nepal",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$50 All Course",
    pricePer: "$5 per month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "$30 All Course",
    pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },
]

export const supportCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    campaignTitle: "Introducing to Software Engineering",
    goalAmount: "50,000",
    raisedAmount: "20,000",
    currency: "₹",
    dcover: "./images/back.webp",
    totalSupporters: 10,
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/c1.png",
    campaignTitle: "Introducing to Software Engineering",
    goalAmount: "50,000",
    raisedAmount: "20,000",
    currency: "₹",
    dcover: "./images/back.webp",
    totalSupporters: 10,
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c1.png",
    campaignTitle: "Introducing to Software Engineering",
    goalAmount: "50,000",
    raisedAmount: "20,000",
    currency: "₹",
    dcover: "./images/back.webp",
    totalSupporters: 10,
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  
  {
    id: 4,
    cover: "../images/courses/c1.png",
    campaignTitle: "Introducing to Software Engineering",
    goalAmount: "50,000",
    raisedAmount: "20,000",
    currency: "₹",
    dcover: "./images/back.webp",
    totalSupporters: 10,
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  }, 
  {
    id: 5,
    cover: "../images/courses/c1.png",
    campaignTitle: "Introducing to Software Engineering",
    goalAmount: "50,000",
    raisedAmount: "20,000",
    currency: "₹",
    dcover: "./images/back.webp",
    totalSupporters: 10,
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  
]

export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
]
export const team = [
  {
    cover: "./images/education.jpg",
    name: "Educational Support",
    work: "Empowering the underprivileged through education.",
  },
  {
    cover: "./images/health.png",
    name: "Healthcare Support",
    work: "Providing essential healthcare services to those in need.",
  },
  {
    cover: "./images/people.jpg",
    name: "Women Empowerment Support",
    work: "Providing essential women empowerment support services",
  }
  
   
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "What is AJ Smile Foundation?",
    desc: "We are an online fundraising platform that empowers individuals. Our crowdfunding platform makes it easy to raise funds from people who share your passion, enabling small contributions to add up and drive meaningful change in India by addressing critical social challenges.",
  },
  {
    title: "How does it work?",
    desc: "AJ Smile Foundation strives to make fundraising fast, easy, affordable, and engaging. You can set up a fundraiser for free on our platform, share it with friends, family, and your social networks, transferred directly to you within two weeks of your fundraiser’s end. And, of course, we support you every step of the way.",
  },
  {
    title: "What are fees and pricing?",
    desc: " Creating a fundraiser on our platform is completely free! Once you’ve raised funds, a platform and transaction fees are free.",
  } 
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
]
