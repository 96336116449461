import React, { useEffect } from "react"

const Focus = ({  children, name = '' }) => {

  useEffect(() => {
    setTimeout(function() {    
    var selector = document.getElementById('focusId');
    if (typeof selector?.scrollIntoView !== 'undefined')
      selector.scrollIntoView();
  }, name = 'campaign-list' ? 800 : 0);
  }, []);

  return (
    <>
      <div id="focusId" className="focus-smouth" >
        {children}
      </div>
    </>
  )
}

export default Focus
