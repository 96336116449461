import axios from "axios";
import firebase from "firebase";
import { REACT_APP_API_URL } from "../config";


const GetCampaginDataById = async (id) => {
  const refcampaginTable = firebase.firestore().collection('campaginTable');
  const campaginData = [];
  await refcampaginTable.where("title", "==", id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        campaginData = doc.data();
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  return campaginData;
}

const ConvertNaNToDecimal = (val) => {
  if (isNaN(val)) {
    return 0;
  } else {
    return parseFloat(val);
  }
}

const ConvertNaNToInt = (val) => {
  if (isNaN(val)) {
    return 0;
  } else {
    return parseInt(val);
  }
}

const GetInitialsOfDisplayName = (displayName) => {
  const nameParts = displayName?.split(" ");
  const firstNameInitial = nameParts[0] ? nameParts[0][0] : "";
  const lastNameInitial = nameParts[1] ? nameParts[1][0] : "";
  return [firstNameInitial, lastNameInitial];
}

const GetFirstNameInitial = (displayName) => {
  const nameParts = displayName?.split(" ");
  const firstNameInitial = nameParts[0] ? nameParts[0][0] : "";
  return firstNameInitial.toUpperCase();
}

const GetLastNameInitial = (displayName) => {
  const nameParts = displayName?.split(" ");
  const lastNameInitial = nameParts[1] ? nameParts[1][0] : "";
  return lastNameInitial.toUpperCase();
}

const UpdateCampaginStatusData = (campaginData, status) => {
  if (campaginData != null && campaginData?.id != null) {
    const refcampaginTable = firebase.firestore().collection('campaginTable');
    campaginData.status = status;
    campaginData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
    if (campaginData != null && campaginData.id) {
      refcampaginTable.doc(campaginData.id)
        .update(campaginData)
        .catch((err) => {
          console.error(err);
        });
    }
  }
}


const UpdateCampaginAmountData = (campaginData, amount, noOfSupporters) => {
  const refcampaginTable = firebase.firestore().collection('campaginTable');
  const newData = campaginData;
  newData.noOfSupporters = ConvertNaNToInt(1 + ConvertNaNToInt(noOfSupporters));
  newData.amount = ConvertNaNToDecimal(ConvertNaNToDecimal(newData.amount) + ConvertNaNToDecimal(amount));
  newData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
  if (newData != null && newData.id) {
    refcampaginTable.doc(newData.id)
      .update(newData)
      .catch((err) => {
        console.error(err);
      });
  }
}

const UpdateCampaginSharedWhatsupData = (campaginData) => {
  const refcampaginTable = firebase.firestore().collection('campaginTable');
  const newData = campaginData;
  newData.sharedCount = ConvertNaNToInt(1 + ConvertNaNToInt(campaginData.sharedCount));
  newData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
  if (newData != null && newData.id) {
    refcampaginTable.doc(newData.id)
      .update(newData)
      .catch((err) => {
        console.error(err);
      });
  }
}



const GetConvertedDate = (createdAt) => {
  return createdAt && createdAt.toDate().getDate()
    + '-' + createdAt.toDate().getMonth('MMM')
    + '-' + createdAt.toDate().getFullYear();
}

const GoogleSiteCaptchaVerify = (token) => {
  const secretKey = "6Lc2DogqAAAAAGzRppsnmSICQSP6H1g6pS0Q0CWz"; // From Google Admin Console

  try {
    const response = axios.post(
      `https://www.google.com/recaptcha/api/siteverify`,
      null,
      {
        params: {
          secret: secretKey,
          response: token,
        },
      }
    );

    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};



const FileUpload = (selectedFile) => {
  const formData = new FormData();
  formData.append("image", selectedFile);

  console.log("selected File:", selectedFile);
  console.log("_formData:", formData.get('image'));
  console.log('REACT_APP_API_URL', REACT_APP_API_URL);

  if (selectedFile != null) {
    try {

      axios.post(REACT_APP_API_URL + "/api/upload",
        { image: selectedFile },
        {
          headers: {
            "content-type": "application/json",
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
          },
        })
        .then((response) => console.log(response))
        .then((data) => console.log(data))
        .catch((error) => console.error('Error:', error));

      // fetch(REACT_APP_API_URL + "/api/upload", {
      //   method: "POST",
      //   body: JSON.stringify(formData),
      //   headers: {
      //     "content-type": "application/json",
      //     "accept": "application/json",
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      //   },
      // }).then((response) => console.log(response))
      //   .then((data) => console.log(data))
      //   .catch((error) => console.error('Error:', error));


    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }
}

const GetFileExtension = (filename) => {
  const match = filename.match(/\.[0-9a-z]+$/i);
  return match ? match[0].substring(1) : "";
};

const FileUploadByBase64 = (base64, filename) => {


  if (!base64) {
    alert("No file selected or converted to Base64!");
    return;
  }

  try {
    const response = fetch(REACT_APP_API_URL + "/api/uploadBase64", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      },
      body: JSON.stringify({ base64, filename: filename }),
    });

    // const data = response?.json();
    // if (response.ok) {
    //   console.log("File uploaded successfully:", data);
    // } else {
    //   console.error("File upload failed:", data);
    // }

  } catch (error) {
    console.log("Error:", error);
  }
};

const FileUploadByBase641 = (imageBase64) => {


  if (imageBase64 != null) {
    try {

      axios.post(REACT_APP_API_URL + "/api/upload",
        { image: imageBase64 },
        {
          headers: {
            "content-type": "application/json",
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
          },
        })
        .then((response) => console.log(response))
        .then((data) => console.log(data))
        .catch((error) => console.error('Error:', error));

      // fetch(REACT_APP_API_URL + "/api/upload", {
      //   method: "POST",
      //   body: JSON.stringify(formData),
      //   headers: {
      //     "content-type": "application/json",
      //     "accept": "application/json",
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      //   },
      // }).then((response) => console.log(response))
      //   .then((data) => console.log(data))
      //   .catch((error) => console.error('Error:', error));


    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }
}


const GetCampaginDataByTitle = async (title, refcampaginTable, refCampaginImageTable) => {
  try {

    const campaginTableDocs = await refcampaginTable.where("title", "==", title).get();
    const campaginDocs = campaginTableDocs.docs.map(doc => doc.data());
    const campaginDoc = campaginDocs.length > 0 ? campaginDocs[0] : null;

    const campaginImageTableDocs = await refCampaginImageTable.where("campaignId", "==", campaginDoc.id).get();
    const campaginImageDocs = campaginImageTableDocs.docs.map(doc => doc.data());
    const campaginImageDoc = campaginImageDocs.length > 0 ? campaginImageDocs[0] : null;


    if (!campaginDoc.empty) {
      return {
        campgainData: { ...campaginDoc, ...campaginImageDoc },
        campgainImageData: campaginImageDoc
      };
    } else {
      throw new Error("Campagin data not found");
    }
  } catch (error) {
    console.error("Error getting Campagin data:", error.message);
    throw error;
  }
};


const GetAllCampagins = async (refcampaginTable, refCampaginImageTable) => {
  try {

    const campaginTableDocs = await refcampaginTable.get();

    const campaginsData = await Promise.all(campaginTableDocs.docs.map(async (doc) => {
      const campaignImageData = await GetCampaginImageDataById(doc.data().id, refCampaginImageTable);
      const campaginTableData = doc.data();

      return ({
        ...campaginTableData,
        ...campaignImageData
      });

    }));

    if (!campaginsData.empty) {
      return campaginsData;
    } else {
      throw new Error("Campagin data not found");
    }
  } catch (error) {
    console.error("Error getting Campagin data:", error.message);
    throw error;
  }
};


const GetCampaginDataByStatus = async (status, refcampaginTable, refCampaginImageTable) => {
  try {

    const campaginTableDocs = await refcampaginTable.where("status", "==", status).get();

    const campaginsData = await Promise.all(campaginTableDocs.docs.map(async (doc) => {
      const campaignImageData = await GetCampaginImageDataById(doc.data().id, refCampaginImageTable);
      const campaginTableData = doc.data();
      return ({
        ...campaginTableData,
        ...campaignImageData
      });
    }));

    if (!campaginsData.empty) {
      return campaginsData;
    } else {
      throw new Error("Campagin data not found");
    }
  } catch (error) {
    console.error("Error getting Campagin data:", error.message);
    throw error;
  }
};

const GetCampaginImageDataById = async (id, refCampaginImageTable) => {
  try {

    const campaginImageTableDocs = await refCampaginImageTable.where("campaignId", "==", id).get();
    const campaginImageDocs = campaginImageTableDocs.docs.map(doc => doc.data());
    const campaginImageDoc = campaginImageDocs.length > 0 ? campaginImageDocs[0] : null;
    return campaginImageDoc;

  } catch (error) {
    console.error("Error getting Campagin Image data:", error.message);
    throw error;
  }
};

export {
  GetCampaginDataById, UpdateCampaginStatusData, GetFirstNameInitial, GetAllCampagins, GetLastNameInitial, GetCampaginDataByStatus,
  UpdateCampaginAmountData, GetInitialsOfDisplayName, UpdateCampaginSharedWhatsupData, GetCampaginDataByTitle,
  ConvertNaNToDecimal, ConvertNaNToInt, GetConvertedDate, GetCampaginImageDataById, GoogleSiteCaptchaVerify, GetFileExtension, FileUpload, FileUploadByBase64
};


