import React, { useEffect, useState } from "react"
import "./view-details.css"
import Heading from "../../common/heading/Heading"
import firebase from "../../../firebase";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { CampaignProgressBar1 } from "../campaign-progress-bar/campaign-progress-bar";
import { ActionControl } from "../actions/action-control";
import { ViewCard, ViewCard2 } from "../top-doners/view-card";
import TopDonersCard from "../top-doners/top-doners-card";
import ModalPopup from "../../common/popup/popup";
import ReactHelmet from "../../../services/react-helmet";
import DonationPopup from "../donation-popup";
import { GetCampaginDataByTitle } from "../../../services/common-service";
 

const ViewDetailsCard = () => { 
  let { id } = useParams();
  let urlPath = window.location.href;
  
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [viewDoc, setViewDoc] = useState(false);
  const [imageSrc, setImageSrc] = useState({});
  const [campaginData, setCampaginData] = useState({});

  const refCampaginTable = firebase.firestore().collection('campaginTable');
  const refCampaginImageTable = firebase.firestore().collection('CampaginImageTable');

  useEffect(() => {
   
    fetchData();

  }, []);

  const fetchData = async () => {
    const { campgainData } = await GetCampaginDataByTitle(id, refCampaginTable, refCampaginImageTable);
    setCampaginData(campgainData);
  };

 



  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  function onHandleCloseClick() {
    setVisibleModal(!isVisibleModal);
    fetchData();
  };

  function onHandleCloseViewDocClick(e) {
    setViewDoc(!viewDoc);
    // setImageSrc(!viewDoc);
  };



  return (
    <>
      {campaginData?.id &&
        <section tabIndex='1' className='homeAbout'>
                  <ReactHelmet
                  title={campaginData.campaignTitle}
                  desc={campaginData.campaignTitle}
                  url={urlPath}
                  imageURL={campaginData.imageURL}/>

          <section className='back3'>  </section>
          <Heading subtitle='Support us' 
          title={`Urgent Help required to our campaign - ${campaginData.campaignTitle}`} />
          <div className='margin-top-201'></div>
          <section className='viewDetailCard '>
            <div className='app-container '>
              <div className='app-row-view-detail'>
                <div className='viewDetailCard'>
                  <div className='items shadow'>
                    <div className='item content'>
                      <div className='details text box'>
                        <div className="header">
                          <button class="btn-green">{campaginData.category.toUpperCase()}</button>
                          <button class="btn-orange">URGENT</button>
                        </div>
                        <div class='sub-header'>
                          <div class="headerCls">
                            <h2><i className='fa fa-star'></i> {campaginData.campaignTitle}   </h2>

                          </div>
                        </div>
                        {campaginData?.status && <CampaignProgressBar1 currentAmount={campaginData.amount}
                          goalAmount={campaginData.goalAmount}
                          status={campaginData.status}
                          sharedCount={campaginData.sharedCount}
                          totalSupporters={campaginData.noOfSupporters}
                          openInNewTab={openInNewTab}
                          setVisibleModal={setVisibleModal}></CampaignProgressBar1>}
                      </div>
                    </div>
                  </div>
                </div>

                <ViewCard2 title='Empty' >
                  <div className='dimg1'>
                    <img src={campaginData.image} alt='' onClick={() => { setViewDoc(!viewDoc); setImageSrc(campaginData.image) }} />
                    <img src={campaginData.additionalImages} alt=''onClick={() => { setViewDoc(!viewDoc); setImageSrc(campaginData.additionalImages) }} />
                  </div>
                </ViewCard2>

                <ViewCard2 title='Story' >
                  <span dangerouslySetInnerHTML={{ __html: campaginData.yourStory }} />
                </ViewCard2>


                <div>
                  <ViewCard2 title='Documents' >
                    <div className='dimg1'>
                    <img src={campaginData.image} alt='' onClick={() => { setViewDoc(!viewDoc); setImageSrc(campaginData.image) }} />
                    <img src={campaginData.additionalImages} alt=''onClick={() => { setViewDoc(!viewDoc); setImageSrc(campaginData.additionalImages) }} />
                    </div>
                  </ViewCard2>
                  <hr />
                  <div className="app-row">
                    <ActionControl status={campaginData.status} openInNewTab={openInNewTab}
                      setVisibleModal={setVisibleModal}></ActionControl>
                  </div>
                </div>

                <br />
                <br />
                <ViewCard title='Top Donations'>
                  {campaginData &&
                    <TopDonersCard campaginId={campaginData.id} type='view-details'></TopDonersCard>}
                </ViewCard>


              </div>
            </div>
            <ModalPopup size="lg" show={viewDoc} variant="warning" 
            onHandleCloseClick={onHandleCloseViewDocClick}>
              <Form>
                <img src={imageSrc} className="popup-image" alt='' />
              </Form>
            </ModalPopup>

            <DonationPopup 
            campaginData ={campaginData} 
            isVisibleModal={isVisibleModal}
             onHandleCloseClick={onHandleCloseClick}
            ></DonationPopup>

          </section >
        </section >
      }
    </>
  )
}

export default ViewDetailsCard
