import React from "react"
import Back from "../back/Back"
import RefundPolicyCard from "./refund-policy-card"
import Focus from "../focus/focus"

const RefundPolicy = () => {
    return (
        <>
            <Focus>
                <Back type='back2' tabindex="1" title='Refund Policy' />
                <RefundPolicyCard />
            </Focus>
        </>
    )
}

export default RefundPolicy
