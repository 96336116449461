// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDuPlxs1HkG8irjpI7M_-Y50VMRN2E2jtA",
  authDomain: "aj-smile-ae206.firebaseapp.com",
  projectId: "aj-smile-ae206",
  storageBucket: "aj-smile-ae206.appspot.com",
  messagingSenderId: "1008934849475",
  appId: "1:1008934849475:web:dd0114637b478845e0cb76",
  measurementId: "G-NPCF9N3LYJ"
};

const firebaseConfig2 = {
  apiKey: "AIzaSyDkzXU__3SQstXeFkv2ld67QIjtcjTI5Uo",
  authDomain: "schoolapp23.firebaseapp.com",
  databaseURL: "https://mybestschoolapp-default-12rtdb.firebaseio.com",
  projectId: "mybestschoolapp12",
  storageBucket: "mybestschoolapp12.appspot.com",
  messagingSenderId: "942961311241",
  appId: "1:9429613141:web:db4cf95b72437d7c4db2dc",
  measurementId: "G-GBX548LNS21K",
};

firebase.initializeApp(firebaseConfig);
console.log(firebase);
export default firebase;