import React from "react"
import { logoPath } from "../../../data"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='app-container flexSB'>
        <div className='app-row '>
          {/* <div className='logo'>
            <img src="../../images/aj-logo2.png" width="70px" height="70px" alt="logo" /> 
            </div> 
            <h1>AJ</h1>
            <span>SMILE FOUNDATION INDIA</span>
          */}

 
    <div class="logo col-12-header">
      <img src="../../images/logo.png" alt="AJ Smile Foundation Logo"/>
    </div>
    <div class=" logo col-85-header">
      <h1>AJ Smile Foundation</h1>
      <span>Spreading Smiles, Transforming Lives</span>
      </div>
          </div> 
          {/* <div className='social'>
            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-instagram icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-youtube icon'></i>
          </div> */}
        </div>
      </section>
    </>
  )
}

export default Head
