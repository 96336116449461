import React, { useEffect, useState } from "react"
import Back from "../../common/back/Back"
import firebase from "firebase";
import { GetUserAdminData, IsUserValid, RemoveUser, SetUserDataInLS } from "../../../services/user-service";


const LoginCard = () => {
    const col_size = 'col-45';
    const [errors, setErrors] = useState({});
    const [userData, setUserData] = useState({ userId: '', password: '', role: 'Admin' });
    useEffect(() => {
        const user = GetUserAdminData('Admin');
        RemoveUser();
        if (user != null) {
            window.location = '/login';
        }
    }, []);

    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    }

    const validateForm = (data) => {
        const newErrors = [];
        if (!data.userId.trim()) {
            newErrors.userId = 'User Id is required';
        } else if (!data.password.trim()) {
            newErrors.password = 'Password is required';
        }

        setErrors(newErrors);
        return newErrors;
    };

    const onSubmit = () => {
        const newErrors = validateForm(userData);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {

            let refLoginAdminTable = firebase.firestore().collection('LoginAdminTable');
            if (userData.userId != "") {
                refLoginAdminTable = refLoginAdminTable.where("userId", "==", userData.userId)
            }
            if (userData.password != "") {
                refLoginAdminTable = refLoginAdminTable.where("password", "==", userData.password)
            }

            refLoginAdminTable.get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data() != null) {
                            SetUserDataInLS(userData.userId, userData.password, 'Admin');
                            window.location = '/admin-campaign';
                        }

                    });
                    if (querySnapshot.empty == true) {
                        const newErrors = [];
                        newErrors.password = 'Invalid UserId and Password';
                        setErrors(newErrors)
                    }
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        }
    }
    return (
        <>
            <Back title='Admin Login' />
            <section className='padding'>
                <div className='app-container'>
                <div class="app-row">
                    <h3 className="mb-4">Login</h3>
                    </div>
                    <div class="app-row">
                        <div class={col_size}>
                            <label for="fname">User Id</label>
                            <input className='flexSB'
                                type="text"
                                name="userId"
                                value={userData.userId}
                                onChange={handleChange}
                                placeholder="User Id" />
                            {errors.userId && (
                                <label className="error-message">
                                    {errors.userId}
                                </label>
                            )}
                        </div>
                    </div>
                    <div class="app-row">
                        <div class={col_size}>
                            <label for="fname">Password</label>
                            <input className='flexSB'
                                type="password"
                                name="password"
                                value={userData.password}
                                onChange={handleChange}
                                placeholder="Password" />
                            {errors.password && (
                                <label className="error-message">
                                    {errors.password}
                                </label>
                            )}
                        </div>
                    </div>
                    <div class="app-row">
                        <div class={col_size}>
                            <button onClick={onSubmit}  >Login</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginCard
