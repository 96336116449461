import React from "react"
import { blog } from "../../../data"
import "./footer.css"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <section className='newletter'>
        <div className='app-container flexSB'>
          <div className='left app-row'>
            <h1>Newsletter - Stay tune and get the latest update</h1>
            <span>Far far away, behind the word mountains</span>
          </div>
          <div className='right app-row'>
            <input type='text' placeholder='Enter email address' />
            <i className='fa fa-paper-plane'></i>
          </div>
        </div>
      </section>
      <footer>
        <div className='app-container padding'>
          <div className=' logo'>
            <h1>AJ</h1>
            <span>Smile Foundaion India</span>
            <p>We not just building lives; We are building a better, more equitable world for all.</p>

            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-instagram icon'></i>
          </div>
          <div className='link'>
            <h3>Explore</h3>
            <ul>
              {/* <li>About Us</li>
              <li>Services</li>
              <li>Courses</li>
              <li>Blog</li>
              <li>Contact us</li> */}

              <li><Link to='/how-it-works'>How it works</Link> </li>
              <li><Link to='/discover'>Discover</Link> </li>
              <li><Link to='/refund-policy'>Refund Policy</Link>  </li>
              <li><Link to='/about'>About Us</Link> </li>
              <li><Link to='/top-doners'>Top Doners</Link> </li>
            </ul>
          </div>
          <div className=' link'>
            <h3>Quick Links</h3>
            <ul>
              <li><Link to='/contact'>Contact Us</Link> </li>
              <li><Link to='/faq'>FAQ</Link> </li>
              <li><Link to='/terms-and-conditions'>Terms & Conditions</Link>  </li>
              <li><Link to='/campaign'>Start a Campaign</Link> </li>
              <li><Link to='/feedback'>Feedbacks</Link> </li>
            </ul>
          </div>
           
          <div className='box last'>
            <h3>Have a Questions?</h3>
            <ul>
              
              <li>
                <i className='fa fa-phone-alt'></i>
                +91-9791994147
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                infoajsmilefoundation@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
          Copyright ©2024 All rights reserved by AJ Smile Foundation 
        </p>
      </div>
    </>
  )
}

export default Footer
