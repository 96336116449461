import React from "react";
import { Button, Form, InputGroup, ListGroup, Modal, Stack } from "react-bootstrap";
import "./popup.css"
 

function ModalPopup({ title, size, show, children, variant, onHandleCloseClick }) {
 
  return (
    <div className="modal show">
      <Modal variant={variant}  size={size} show={show}
       dialogClassName="test"
        onHide={onHandleCloseClick} >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Button variant={variant} onClick={onHandleCloseClick}  >Close</Button>
          </Form.Group>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalPopup