import React from "react"
import Focus from "../../common/focus/focus"
import ViewDetailsCard from "./view-details-card"
import Back from "../../common/back/Back"

const ViewDetails = () => {
    return (
        <>
   
            <Focus>
                <Back type='back' title='View Details' />
                <ViewDetailsCard />
            </Focus>
        </>
    )
}

export default ViewDetails
