import React, { useEffect, useState } from 'react';
import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';
import Step5 from './steps/step5';
import Step6 from './steps/step6';
// import Success from './Success';
import firebase from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { useParams } from 'react-router-dom';
import './multi-steps.css';
import Step7 from './steps/step7';

import axios from 'axios';
import { FileUpload, FileUploadByBase64, GetCampaginDataByTitle, GetCampaginImageDataById, GetFileExtension } from '../../services/common-service';

const MultiStepForm = () => {
  let { id } = useParams();
  const basePath = 'images/';
  let URLPath = window.location.origin;
  const [status, setStatus] = useState('');
  const [imageFile, setImageFile] = useState('');
  const _campaignId = uuidv4();
  const storageRef = firebase.storage().ref();
  const refcampaginTable = firebase.firestore().collection('campaginTable');
  const refCampaginImageTable = firebase.firestore().collection('CampaginImageTable');
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    id: _campaignId,
    title: '',
    campaignTitle: '',
    country: 'India',
    currentAmount: 0,
    goalAmount: 0,
    currency: 'INR',
    image: '',
    imageURL: '',
    additionalImages: '',
    category: '',
    location: '',
    endDate: '',
    yourStory: '',
    type: '',
    noOfSupporters: 0,
    campaignerName: '',
    campaignerEmailId: '',
    campaignerPhone: '',
    campaignerIdProof: '',
    beneficiaryName: '',
    beneficiaryEmailId: '',
    beneficiaryPhone: '',
    beneficiaryDOB: '',
    beneficiaryIdProof: '',
    beneficiaryAddress: '',
    beneficiaryAddress1: '',
    beneficiaryAddress2: '',
    beneficiaryCity: '',
    beneficiaryState: '',
    beneficiaryBankName: '',
    beneficiaryIFSCCode: '',
    beneficiaryAccountNo: '',
    beneficiaryAccountHolderName: '',
    isActive: true,
    isAcceptedTermsAndCondition: false,
    status: 'Submit',
    sharedCount: 0
  });

  const [formCampaignImageData, setFormCampaignImageData] = useState({
    imageId: uuidv4(),
    campaignId: _campaignId,
    image: '',
    additionalImages: '',
    campaignerIdProof: '',
    beneficiaryIdProof: '',
  });




  useEffect(() => {

    if (id != null) {
      const fetchData = async () => {
        const { campgainData , campgainImageData } = await GetCampaginDataByTitle(id, refcampaginTable, refCampaginImageTable);
        setFormData(campgainData);
        setFormCampaignImageData(campgainImageData);
      };

      fetchData();
    }
  }, []);


 

  const nextStep = () => {
    focus();
    setStep(step + 1);
  };


  const handleSubmit = () => {
    if (id == null) {
      formData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      formData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
      formCampaignImageData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      formCampaignImageData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
      //formData.image ='';

      setFormCampaignImageData({
        ...formCampaignImageData,
        ...formData
      });

      let _formData = formData;
      _formData.image = '';
      _formData.additionalImages = '';
      _formData.campaignerIdProof = '';
      _formData.beneficiaryIdProof = '';

      setFormData(_formData);

      refCampaginImageTable.doc(formCampaignImageData.imageId)
        .set(formCampaignImageData)
        .then(() => {

          refcampaginTable.doc(_formData.id)
            .set(_formData)
            .then(() => {
              setStatus('success');
              setStep(step + 1);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });

    } else {
      formData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
      formCampaignImageData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
      refCampaginImageTable.doc(formCampaignImageData.imageId)
        .update(formCampaignImageData)
        .then(() => {
          formData.image = '';
          refcampaginTable.doc(formData.id)
            .update(formData)
            .then(() => {
              setStatus('update');
              setStep(step + 1);
            })
            .catch((err) => {
              console.error(err);
            });

        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  const focus = () => {
    var selector = document.getElementById('steps');
    if (typeof selector?.scrollIntoView !== 'undefined')
      selector.scrollIntoView();
  };



  const prevStep = () => {
    focus();
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    try {
      if (name == 'isAcceptedTermsAndCondition') {
        setFormData({ ...formData, [name]: e.target.checked });
      } else if (name == 'image' || name == 'additionalImages' ||
        name == 'campaignerIdProof' || name == 'beneficiaryIdProof') {
        const reader = new FileReader();
        const selectedFile = e.target.files[0];

        if (selectedFile) {
          reader.readAsDataURL(selectedFile);
        }
        reader.onload = (readerEvent) => {
          if (readerEvent.target.result) {
            setFormCampaignImageData({ ...formCampaignImageData, [name]: readerEvent.target.result });
            setFormData({ ...formData, [name]: readerEvent.target.result });
            // if (name == 'image') {
            //   const filename = `${formData.id}.` + GetFileExtension(selectedFile.name);
            //   //const filePath = URLPath + '/files/' + filename;
            //   setFormData({ ...formData, [name]: readerEvent.target.result });
            //  // FileUploadByBase64(readerEvent.target.result, filename);
            //   // setTimeout(() => {
            //   //   setFormData({ ...formData, ['imageURL']: filePath });
            //   // }, 2000);

            // }  
          }
        };
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const fileUploadInStorage = (file, name) => {
    var uploadTask = storageRef.child(basePath + file.name).put(file);
    uploadTask.on('state_changed',
      (snapshot) => {

      },
      (error) => {
        console.error(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setFormData({ ...formData, [name]: downloadURL });
        });
      }
    );
  }


  switch (step) {
    case 1:
      //return <Step6 prevStep={prevStep} handleChange={handleChange} handleSubmit={handleSubmit} values={formData} />;
      //return <Step2 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} values={formData} />;
      return <Step1 nextStep={nextStep} handleChange={handleChange} values={formData} />;
    case 2:
      return <Step2 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} values={formData} />;
    case 3:
      return <Step3 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} values={formData} />;
    case 4:
      return <Step4 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} values={formData} />;
    case 5:
      return <Step5 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} values={formData} />;
    case 6:
      return <Step6 prevStep={prevStep} handleChange={handleChange} handleSubmit={handleSubmit} values={formData} />;
    case 7:
      return <Step7 status={status} />;
    default:
      return <Step1 nextStep={nextStep} handleChange={handleChange} values={formData} />;
  }
};

export default MultiStepForm;