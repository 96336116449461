import React from "react"
import Back from "../back/Back"
import TermsAndConditionCard from "./terms-conditions-card"
import Focus from "../focus/focus"

const TermsAndCondition = () => {
    return (
        <>
            <Focus>
                <Back type='back2' title='Terms and Condition' />
                <TermsAndConditionCard />
            </Focus>
        </>
    )
}

export default TermsAndCondition
