import React from "react"
import Back from "../back/Back"
import "./how-it-works.css"
import HowItWorksCard from "./how-it-works-card"
import Focus from "../focus/focus"

const HowItWorks = () => {
  return (
    <>
      <Focus>
        <Back tabindex="1" title='How It Works' />
        <HowItWorksCard id="back" />
      </Focus>
    </>
  )
}

export default HowItWorks
