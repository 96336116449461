import React from "react"
import Back from "../common/back/Back"
// import "./blog.css"
import MultiStepForm from "./multi-steps"
import Focus from "../common/focus/focus"

const Campaign = () => {
  return (
    <>
      <Focus>
      <Back title='Create a new Campaign' />
      <section className='blog padding'>
        <div className='app-container'>
        <MultiStepForm />   
        </div>
      </section>
      </Focus>
    </>
  )
}

export default Campaign
