import React from "react"
import Back from "../common/back/Back"
import DiscoverCard from "./discover-card"
import Focus from "../common/focus/focus"

const DiscoverHome = () => {
  return (
    <>
     <Focus name='campaign-list'>
      <Back title='Explore Campaign' />
      <DiscoverCard /> 
      </Focus>
    </>
  )
}

export default DiscoverHome
