import React from "react"
import Back from "../common/back/Back"
import DiscoverCard from "./discover-card"
import AdminCampaginList from "../admin/admin-campagin-list"

const AdminDiscover = () => {
  return (
    <>
      <Back title='Explore Admin Campaign' />
      <DiscoverCard />
      <AdminCampaginList/>
    </>
  )
}

export default AdminDiscover
