import React from "react"
import Back from "../back/Back"
// import AboutCard from "./AboutCard"

const Privacy = () => {
  return (
    <>
      <Back title='FAQ' />
      {/* <AboutCard /> */}
    </>
  )
}

export default Privacy
