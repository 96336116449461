import React, { useState } from "react"
import Heading from "../heading/Heading"
import { faq } from "../../../data"


// const FAQCard1 = () => {
//   return (
//     <>
//       <section className='aboutHome'>
//         <div className='app-container'>
//           <div className='app-row'>
//             <Heading subtitle='What is AJ Smile Foundation' title='' />
//             <div className='text'>
//               <p>
//               </p> 
//             </div> 
//           </div>

//           <div className='app-row'>
//             <Heading subtitle='How does it work?' title='' />
//             <div className='text'>
//               <p> 
//               </p> 
//             </div> 
//           </div>

//           <div className='app-row'>
//             <Heading subtitle='' title='' />
//             <div className='text'>
//               <p>
//               </p> 
//             </div> 
//           </div>
//           <br/>
//         </div>
//       </section>
    
//     </>
//   )
// }


const FAQCard = () => {
  const [click, setClick] = useState(false)

  const toggle = (index) => {
    if (click === index) {
      return setClick(null)
    }
    setClick(index)
  }

  return (
    <>
      <Heading subtitle='FAQS' title='Frequesntly Ask Question' />
      <section className='faq'>
        <div className='app-container'>
          {faq.map((val, index) => (
            <div className='box'>
              <button className='accordion' onClick={() => toggle(index)} key={index}>
                <h2>{val.title}</h2>
                <span>{click === index ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
              </button>
              {click === index ? (
                <div className='text'>
                  <p>{val.desc}</p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default FAQCard
