import React, { useState } from 'react';
import "./steps.css"
import "./fileupload.css"

const Step2 = ({ nextStep, prevStep, handleChange, values }) => {

  const [errors, setErrors] = useState({});
  const handleSubmit = (e, nextStep, formData) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      nextStep();
    }
  };

  const handleChange1 = (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0];
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    const newErrors = [];
    const fileExtension = selectedFile?.name?.split(".").at(-1);

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    console.log("Step 2 formData:",formData);
    console.log("Step 2 image:",formData.get('image'));

    if (name == "image") {
      if (!allowedFileTypes.includes(fileExtension)) {
        newErrors.image = "File does not support. You must use .png or .jpg";
      }
      else if (selectedFile.size > 10e6) {
        newErrors.image = "Please upload a file smaller than 10 MB";
      }
    } else if (name == "additionalImages") {
      if (!allowedFileTypes.includes(fileExtension)) {
        newErrors.additionalImages = "File does not support. You must use .png or .jpg";
      }
      else if (selectedFile.size > 10e6) {
        newErrors.additionalImages = "Please upload a file smaller than 10 MB";
      }
    }


    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleChange(e);
    }
  };



  const validateForm = (data) => {
    const newErrors = [];
    if (!data.image.trim()) {
      newErrors.image = 'Image is required';
    }
    if (!data.additionalImages.trim()) {
      newErrors.additionalImages = 'Additional image is required';
    }
    if (!data.category.trim()) {
      newErrors.category = 'Category is required';
    }
    if (!data.location.trim()) {
      newErrors.location = 'Location is required';
    } else if (data.location.length < 3) {
      newErrors.location = 'Location must be at least 3 digit long';
    }
    if (!data.endDate.trim()) {
      newErrors.endDate = 'EndDate is required';
    }


    setErrors(newErrors);
    return newErrors;
  };



  return (
    <div tabindex="1"  id="steps">
      <div class="stepper-wrapper">
        <div class="stepper-item completed">
          <div class="step-counter">1</div>
        </div>
        <div class="stepper-item active">
          <div class="step-counter">2</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">3</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">4</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">5</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">6</div>
        </div>
      </div>

      <div class="app-container">
      <h2>Step 2: About your fundraiser</h2>
      <br />
      <br />
      <div class="app-row">
        <div class="col-75">


          <label>
            Add image:
            <div class="drop-zone">

              {values.image ? <img src={values.image} className="image" alt="preview" /> :
                <span class="drop-zone__prompt">Drop file here or click to upload</span>}

              <input
                type="file"
                name="image"
                accept="image/*"
                class="drop-zone__input"
                onChange={handleChange1}></input>
            </div>
            {errors.image && (
              <label className="error-message">
                {errors.image}
              </label>
            )}
          </label>
        </div>
      </div>

      <div class="app-row">
        <div class="col-75">
          <label>
            Add additional images for the gallery:

            <div class="drop-zone">
              {values.additionalImages ? <img src={values.additionalImages} className="image" alt="preview" /> :
                <span class="drop-zone__prompt">Drop file here or click to upload</span>}
              <input
                type="file"
                name="additionalImages"
                class="drop-zone__input"
                onChange={handleChange1}></input>

            </div>
            {errors.additionalImages && (
              <label className="error-message">
                {errors.additionalImages}
              </label>
            )}
          </label>
        </div>
      </div>
      <div class="app-row">
        <div class="col-75">
          <label>
            Category:
            <select   name="category" value={values.category}
              onChange={handleChange}>
              <option value="" class="placeholder" >Choose a category</option>
              <option value="Business Uplift">Business Uplift</option>
              <option value="Children">Children</option>
              <option value="Community">Community</option>
              <option value="Creative">Creative</option>
              <option value="Education">Education</option>
              <option value="Emergency Relief">Emergency Relief</option>
              <option value="Food / Water">Food / Water</option>
              <option value="Medical">Medical</option>
              <option value="Orphan">Orphan</option>
              <option value="Other">Other</option>
              <option value="Zakath">Zakath</option>
              <option value="Refugee">Refugee</option>
              <option value="Women">Women</option>
            </select>
            {errors.category && (
              <label className="error-message">
                {errors.category}
              </label>
            )}
          </label>
        </div>
      </div>
      <div class="app-row">
        <div class="col-75">
          <label>
            Location:
            <input  
              type="text"
              name="location"
              value={values.location}
              onChange={handleChange}
            />
            {errors.location && (
              <label className="error-message">
                {errors.location}
              </label>
            )}
          </label>
        </div>
      </div>
      <div class="app-row">
        <div class="col-75">
          <label>
            End Date:
            <input
              type="date"  
              name="endDate"
              value={values.endDate}
              onChange={handleChange}
            />
            {errors.endDate && (
              <label className="error-message">
                {errors.endDate}
              </label>
            )}
          </label>
        </div>
      </div>
      <br />
      <div class="app-row">
      <button className='primary-btn'  onClick={prevStep}>Back</button>
      <button  className='secoundry-btn' onClick={(e) => handleSubmit(e, nextStep, values)}>Next</button>
      </div>
    </div>
    </div>
  );
};

export default Step2;