


function SetUserDataInLS(userId, password, role) {
  localStorage.setItem('user', JSON.stringify({ userId: userId, password: password, role: role }));
}

function GetUserAdminData(role) {
  let user = JSON.parse(localStorage.getItem('user'));
  return user && user.role == role;
}

function IsUserValid(userId, password) {
  let user = JSON.parse(localStorage.getItem('user'));
  return user && user.userId == userId && user.password == password;
}

function RemoveUser() {
  localStorage.removeItem('user');
}

export { IsUserValid, SetUserDataInLS, RemoveUser, GetUserAdminData };


