import React from "react";
import { Button, Form, InputGroup, ListGroup, Modal, Stack } from "react-bootstrap";


function CommonModal({ show, children, variant, onHandleClick, onHandleCloseClick }) {

  return (
    <div className="modal show">
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <Modal show={show} >
        <Modal.Header closeButton>
          <Modal.Title>Donation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
         
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Button variant="secondary" onClick={onHandleCloseClick}  >Close</Button>
            <Button variant={variant} onClick={onHandleClick} id="button-addon2">
              Donate  now  !!
            </Button>
          </Form.Group>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CommonModal