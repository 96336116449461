import React, { useState } from "react"
import "./feedback.css" 
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import Heading from "../common/heading/Heading";

const FeedbackUsCard = () => {
  const [status, setStatus] = useState('');
  const refContactUsTable = firebase.firestore().collection('ContactUsTable');
  const [errors, setErrors] = useState({});
  const intialReq = {
    id: uuidv4(),
    name: '',
    email: '',
    subject: '',
    message: '',
    type: 'Feedback'
  };
  const [contactData, setContactData] = useState(intialReq);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm(contactData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {

      contactData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      contactData.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();
      refContactUsTable.doc(contactData.id)
        .set(contactData)
        .then(() => {
          setContactData(intialReq);
          setStatus('success');
        })
        .catch((err) => {
          console.error(err);
        });

    }
  };

  const validateForm = (data) => {
    const newErrors = [];
    if (!data.name.trim()) {
      newErrors.name = 'Name is required';
    } else if (data.name.length < 4) {
      newErrors.name = 'Name must be at least 4 characters long';
    }
    if (!data.email.trim()) {
      newErrors.email = 'Email is required';
    }
    if (!data.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    if (!data.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setContactData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
        <section className='app-container'>
          <div className="contents padding">
          <Heading className="" subtitle='Feedback Us' title='' />
            <form action=''>

              {status === 'success' && (<div className="success">
                <h5><strong>Success!</strong> You have send message successfully !!!</h5>
              </div>)}


              <div class="app-row ">
              
                <input type='text' placeholder='Name'
                  name="name"
                  onChange={handleOnChange}
                  value={contactData.name} />
                {errors.name && (
                  <label className="error-message">
                    {errors.name}
                  </label>
                )}
                
              </div>
              <div class="app-row">
                <input type='email' placeholder='Email'
                  name="email"
                  onChange={handleOnChange}
                  value={contactData.email} />
                {errors.email && (
                  <label className="error-message">
                    {errors.email}
                  </label>
                )}
              </div>
              <div class="app-row">
                <input type='text' placeholder='Subject'
                  onChange={handleOnChange}
                  name="subject" value={contactData.subject} />
                {errors.subject && (
                  <label className="error-message">
                    {errors.subject}
                  </label>
                )}
              </div>
              <div class="app-row">
                <textarea cols='30' rows='10'
                  name="message" placeholder='Message'
                  onChange={handleOnChange}
                  value={contactData.message}>
                </textarea>
                {errors.message && (
                  <label className="error-message">
                    {errors.message}
                  </label>
                )}
              </div>
              <div class="app-row">
                <button className='primary-btn' onClick={handleSubmit}>SEND MESSAGE</button>
              </div>
            </form>
          </div>
<br/>

        </section>
    </>
  )
}

export default FeedbackUsCard
