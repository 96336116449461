import React, { useEffect, useState } from "react"
import Back from "../../common/back/Back"
import LoginCard from "./loginCard";
import Focus from "../../common/focus/focus";

const Login = () => {
  return (
    <>
      <Focus>
        <Back tabindex="1" title='Login' />
        <LoginCard id="back" />
      </Focus>
    </>
  )
}

export default Login
