import React from "react"
import { useLocation } from "react-router-dom"

const Back = ({ title, className }) => {
  const location = useLocation();

  var selector = document.getElementById('back');
  if (typeof selector?.scrollIntoView !== 'undefined')
    selector.scrollIntoView();

  return (
    <>
      <section className={ className != null ? className : 'back' } >
        <h3>Home / {location.pathname.split("/")[1]}</h3>
        <h1>{title}</h1>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Back
