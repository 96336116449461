import "./App.css"
import Header from "./components/common/header/Header"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import About from "./components/about/About"
import Footer from "./components/common/footer/Footer"
import Home from "./components/home/Home"
import Campaign from "./components/campaign/campaign"
import DiscoverHome from "./components/discovers/discover-home"
import AdminViewDetails from "./components/admin/admin-view-details"
import ViewDetails from "./components/discovers/view-details/view-details"
import Login from "./components/admin/login/login"
import AdminDiscover from "./components/discovers/admin-discover"
import TermsAndCondition from "./components/common/terms-conditions/terms-conditions"
import Privacy from "./components/common/privacy/privacy"
import FAQ from "./components/common/faq/faq"
import Feedbacks from "./components/common/feedbacks/feedbacks"
import FAQForm from "./components/common/faq/faq"
import HowItWorks from "./components/common/how-it-works/how-it-works"
import RefundPolicy from "./components/common/refund-policy/refund-policy"
import TopDoners from "./components/discovers/top-doners/top-doners"
import ContactUs from "./components/contact-us/contact-us"
import FeedbackUs from "./components/feedback-us/feedback-us"
import EmailSender from "./components/common/send-email/email-sender"
import ServicePage from "./components/service/service"
 

 
function App() {
  return (
    <>
      <BrowserRouter>
    
        <Header />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element ={<About/>} />
          <Route path='/discover' element={<DiscoverHome/>} />
          <Route path='/campaign' element={<Campaign/> } />
          <Route path='/contact' element={<ContactUs/> } />
          <Route path='/admin-campaign' element={<AdminDiscover/> } />
          <Route path='/terms-and-conditions' element={<TermsAndCondition/> } />
          <Route path='/faq' element={<FAQForm/> } />
          <Route path='/how-it-works' element={<HowItWorks/> } />
          <Route path='/refund-policy' element={<RefundPolicy/> } />
          <Route path='/feedbacks' element={<Feedbacks/> } />
          <Route path='/top-doners' element={<TopDoners/> } />
          <Route path='/privacy' element={<Privacy/> } />
          <Route path='/faq' element={<FAQ/> } />
          <Route path='/view-details/:id' element={<ViewDetails/> } />
          <Route path='/admin-view-details/:id' element={<AdminViewDetails/> } />
          <Route path='/campaign-form/:id' element={<Campaign/> } />
          <Route path='/login' element={<Login/> } />  
          <Route path='/feedback' element={<FeedbackUs/> } />  
          <Route path='/send-email' element={<EmailSender/> } />  

          <Route path='/service' element={<ServicePage/> } />
          </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
