import axios from "axios";
import { REACT_APP_API_URL } from "../config";
 


const SendEmailMessage = (campaginData, campaginDonationData, htmlEmailContent) => {
    let URLPath = window.location.origin;
    const apiKey = "197411ce806aa978c5654897a92e34de"; // Replace with your API key
    const _htmlEmailContent =
        htmlEmailContent
            .replace('{donarName}', campaginDonationData?.name)
            .replace('{receiptNumber}', campaginDonationData?.id)
            .replace('{donationDate}', (new Date()).toString().substring(0, 21))
            .replace('{donationAmount}', '₹ ' + campaginDonationData?.amount)
            .replace('{paymentMethod}', campaginDonationData?.paymentMethod)
            .replace('{purposeofDonation}', ' to provide ' + campaginData?.category + ' fund for ' + campaginData?.campaignerName)
            .replace('{donarName1}', campaginDonationData?.name)
            .replace('{purposeofDonation1}', 'To provide ' + campaginData?.category + ' fund for ' + campaginData?.campaignerName);




    //v[specific impact, e.g., provide education, fund medical care, etc.]
    const emailPayload = {
        from: { name: "AJ Smile Foundation", email: "hello@aj-smile-foundation.com" },
        to: [{ name: campaginDonationData?.name, email: campaginDonationData?.email }],
        toEmail: { name: campaginDonationData?.name, email: campaginDonationData?.email },
        subject: 'Thank You for Your Support and Donation!',
        html: _htmlEmailContent,
        category: "Integration Test"
    };



    // try {
    //     axios.post(
    //         "http://localhost:4000/api/send-mail",
    //         emailPayload,
    //         {
    //             headers: {
    //                 "content-type": "application/json",
    //                 "Accept": "application/json",
    //                 "Authorization": "Bearer " + apiKey,
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
    //                 "Access-Control-Allow-Headers": " Content-Type, Authorization",
    //                 "Access-Control-Allow-Credentials": "true",
    //             },
    //         }
    //     )
    // } catch (error) {
    //     console.error("Error sending email:", error);
    // }

    axios
      .post(REACT_APP_API_URL +"/api/send-mail", emailPayload)
      .then((response) => console.log(response))
        .then((data) => console.log(data))
        .catch((error) => console.error('Error:', error));
      

    // fetch('http://localhost:4000/api/send-mail', {
    //     method: 'POST',
    //     headers: {
    //         "content-type": "application/json", 
    //         "Accept": "application/json", 
    //         "Authorization": "Bearer "+ apiKey,
    //     },
    //     body: JSON.stringify(emailPayload) 
    //   }).then((response) => console.log(response.json()))
    //     .then((data) => console.log(data))
    //     .catch((error) => console.error('Error:', error));

    // try {
    //     axios.post(
    //         "https://send.api.mailtrap.io/api/send",
    //         emailPayload,
    //         {
    //             headers: {
    //                 "content-type": "application/json", 
    //                 "Accept": "application/json", 
    //                 "Authorization": "Bearer "+ apiKey,
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
    //                 "Access-Control-Allow-Headers": " Content-Type, Authorization",
    //                 "Access-Control-Allow-Credentials": "true",
    //             },
    //         }
    //     )     
    // } catch (error) {
    //     console.error("Error sending email:", error);
    // }
};

const SendEmailMessageOld = (campaginData, campaginDonationData, htmlEmailContent) => {
    const apiKey = "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"; // Replace with your API key
    const _htmlEmailContent =
        htmlEmailContent
            .replace('{donarName}', campaginDonationData?.name)
            .replace('{receiptNumber}', campaginDonationData?.id)
            .replace('{donationDate}', (new Date()).toString().substring(0, 21))
            .replace('{donationAmount}', '₹ ' + campaginDonationData?.amount)
            .replace('{paymentMethod}', campaginDonationData?.paymentMethod)
            .replace('{purposeofDonation}', ' to provide ' + campaginData?.category + ' fund for ' + campaginData?.campaignerName)
            .replace('{donarName1}', campaginDonationData?.name)
            .replace('{purposeofDonation1}', 'To provide ' + campaginData?.category + ' fund for ' + campaginData?.campaignerName);



    //v[specific impact, e.g., provide education, fund medical care, etc.]
    const emailPayload = {
        sender: { name: "AJ Smile Foundation", email: "javidat2020@gmail.com" },
        to: [{ name: campaginDonationData?.name, email: campaginDonationData?.email }],
        subject: 'Thank You for Your Support and Donation!',
        htmlContent: _htmlEmailContent
    };

    try {
        const response = axios.post(
            "https://api.brevo.com/v3/smtp/email",
            emailPayload,
            {
                headers: {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "api-key": apiKey,
                },
            }
        );
        console.log("Email sent successfully:", response.data);
    } catch (error) {
        console.error("Error sending email:", error);
    }
};
export { SendEmailMessage };