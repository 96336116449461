import React, { useState } from 'react';
import "./steps.css"
import TextEditor from './text-editor';


// import MarkdownPreview from './markdown-preview';
const Step3 = ({ nextStep, prevStep, handleChange, values }) => {
  const [val, setVal] = useState(values.yourStory);
  const onChange = (value) => {
    const target = { target: { name: 'yourStory', value: value.toString('html') } };
    //setEditorValue(value);
    handleChange(target);
    setVal(value);
  };
  const [errors, setErrors] = useState({});


  const handleSubmit = (e, nextStep, formData) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      nextStep();
    }
  };



  const textEditorOnChange1 = (value) => {
    const target = { target: { name: 'yourStory', value: value.toString('html') } };
    //setEditorValue(value);
    //handleChange(target);
  };

  const validateForm = (data) => {
    const newErrors = [];
    if (!data.yourStory.trim()) {
      newErrors.yourStory = 'Your Story is required';
    } else if (data.yourStory.length < 200) {
      newErrors.yourStory = 'Your Story must be at least 200 char long';
    }
    if (!data.type.trim()) {
      newErrors.type = 'Type is required';
    }

    setErrors(newErrors);
    return newErrors;
  };

  return (
    <div tabindex="1" id="steps">

      <div class="stepper-wrapper">
        <div class="stepper-item completed">
          <div class="step-counter">1</div>
        </div>
        <div class="stepper-item completed">
          <div class="step-counter">2</div>
        </div>
        <div class="stepper-item active">
          <div class="step-counter">3</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">4</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">5</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">6</div>
        </div>
      </div>

      
      <div class="app-container">
      <h2>Step 3: Tell your story</h2>
      <br />
      <br />
        <div class="app-row">
          <div class="col-75">
            <label for="fname"> Your Story
              <div >
                <TextEditor markup={val} tabindex="1" value={val} onChange={onChange} />
              </div>
              {errors.yourStory && (
                <label className="error-message">
                  {errors.yourStory}
                </label>
              )}
            </label>
          </div>
        </div>
        <div class="app-row">
          <div class="col-75">
            <label for="fname">  Type:
              <select name="type" value={values.type}
                onChange={handleChange}>
                <option value="" class="placeholder" >Choose a type</option>
                <option value="Donation">Donation</option>
                <option value="Zakath">Zakath</option>
                <option value="Sadaqah">Sadaqah</option>
              </select>
              {errors.type && (
                <label className="error-message">
                  {errors.type}
                </label>
              )}
            </label>
          </div>
        </div>
        <br />
        <button className='primary-btn' onClick={prevStep}>Back</button>
        <button className='secoundry-btn' onClick={(e) => handleSubmit(e, nextStep, values)} >Next</button>
      </div>
    </div>
  );
};

export default Step3;

