import React from "react"
import Heading from "../heading/Heading"


const RefundPolicyCard = () => {
  return (
    <>
      <section tabindex="1" className='aboutHome'>
        <div className='app-container'>
          <div class="app-row">
            <Heading subtitle='Refund Policy' title='' />
          </div>
          <br />
          <div class="app-row">
            <p>Last updated on Oct 14 2024 </p>
            <br />
            <p>
            AJ Smile Foundation (AJ EDUCATIONAL & CHARITABLE TRUST) is a non-profit organization (Registration No: AAFTA2134CE20221) founded by a dedicated group committed to identifying and supporting individuals across India who are deprived of basic necessities.
            </p>
            <p>
              Our mission extends beyond addressing immediate needs; we aim to empower these individuals through access to education, healthcare, employment opportunities, and essential services.
            </p>
          </div>

          <div class="app-row">
            <p>
              Refund Policy: AJ Smile Foundation does not issue refunds for any donations received. All contributions are considered final, voluntary, and non-refundable.
            </p>
            <p>
              All funds received by AJ Smile Foundation are directed towards specific initiatives, programs, and recipients aligned with our mission to provide education, medical assistance, and essential services.
            </p>
          </div>
        </div>
        <br />
      </section>

    </>
  )
}

export default RefundPolicyCard
