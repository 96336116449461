import React, { useState } from "react"
import { Link } from "react-router-dom"
import Head from "./Head"
import "./header.css"
import { GetUserAdminData } from "../../../services/user-service"

const Header = () => {
  const [click, setClick] = useState(false)
  const userAdmin = GetUserAdminData('Admin');
  return (
    <>
      <Head />
      <header>
        <nav className='flexSB'>
          
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
            <li>
              <Link to='/'>Home</Link>
            </li>
            {!userAdmin && <li>
              <Link to='/discover'>Discover</Link>
            </li>}
            
            <li>
              <Link to='/about'>About</Link>
            </li>
            {userAdmin && <li>
              <Link to='/admin-campaign'>Admin </Link>
            </li>}

              {userAdmin && <li>
              <Link to='/team'> Team</Link>
            </li>}
            {userAdmin && <li>
              <Link to='/pricing'> Pricing</Link>
            </li>}   
            <li>
              <Link to='/campaign'>Campaign</Link>
            </li>
            <li>
              <Link to='/service'>Services</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>

            <li>
              <Link to='/login'>Admin login</Link>
            </li>
          </ul>

          <div className='start'>
            {/* */}
            <div className='button'> <Link to='/discover'>DONATE NOW</Link></div> 
          </div>



          <button className='toggle' onClick={() => setClick(!click)}>
            {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
          </button>
        </nav>

      </header>

    </>
  )
}

export default Header
