import React from "react"
import Heading from "../../common/heading/Heading"
// import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='app-container'>
          <div className='app-row'>
            <Heading subtitle='WELCOME TO ' 
            title='AJ SMILE FOUNDATION ORGANIZATION' />
            <p>We are not just building lives; We are building a better, more equitable world for all.</p>
            <div className='button'>
              <button className='primary-btn'>
                DISCOVER <i className='fa fa-long-arrow-alt-right'></i>
              </button>
              <button>
                GIFT A SMILE :) <i className='fa fa-long-arrow-alt-right'></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero
