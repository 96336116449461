import React, { useEffect, useState } from "react"
import Heading from "../common/heading/Heading"
import "../discovers/discover.css"
import firebase from "../../firebase";
import { Link } from "react-router-dom";
import { CampaignProgressBar } from "./campaign-progress-bar/campaign-progress-bar";
import { ActionControl, ActionControl2 } from "./actions/action-control";
import ReactHelmet from "../../services/react-helmet";
import { Helmet } from "react-helmet";
import { GetCampaginDataByStatus } from "../../services/common-service";

const DiscoverCard = () => {
  const [campaginRejectedData, setCampaginRejectedData] = useState([]);
  const [campaginApprovedData, setCampaginApprovedData] = useState([]);
  const refcampaginTable = firebase.firestore().collection('campaginTable');
  const refCampaginImageTable = firebase.firestore().collection('CampaginImageTable');

  useEffect(() => {


    const fetchData = async () => {
      const _campaginApprovedData = await GetCampaginDataByStatus('Approved', refcampaginTable, refCampaginImageTable);
      setCampaginApprovedData(_campaginApprovedData);

      const _campaginRejectedData = await GetCampaginDataByStatus('Closed', refcampaginTable, refCampaginImageTable);
      setCampaginRejectedData(_campaginRejectedData);
    };

    fetchData();
 
  }, []);
  return (
    <>
      <section className='padding'>

        <Helmet>
          {campaginApprovedData.map((campaginData, index) => (
            <div>
              <React.Fragment key={index}>
                <meta property="og:title" content={campaginData.campaignTitle} />
                <meta property="og:description" content={campaginData.campaignTitle} />
                <meta property="og:url" content={window.location.href + `/view-details/${campaginData.title}`} />
                <meta property="og:image" content={campaginData.imageUrl} />
              </React.Fragment>
            </div>
          ))}
        </Helmet>

        <div className=''>
          <Heading subtitle='Support us' title='explore our campaign' />
          <div className='discoverCard'>
            <div className='app-container grid2'>

              {campaginApprovedData.slice(0, 5).map((campaginData) => (
                <div className='items shadow'>
                  <div className='flex'>
                    <div className='left text'>
                      <div className="header">
                        <button class="btn-green">{campaginData.category.toUpperCase()}</button>
                        <button class="btn-orange">URGENT</button>
                      </div>
                      <h3><i className='fa fa-star'></i> {campaginData.campaignTitle.substring(0, 50)}..
                      </h3>
                      <br />
                      <div className='details'>
                        <div className='box'>
                          <div className='dimg1'>
                            <img src={campaginData.image} alt='' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=''>
                    <CampaignProgressBar
                      sharedCount={campaginData.sharedCount}
                      currentAmount={campaginData.amount}
                      totalSupporters={campaginData.noOfSupporters ?? 0}
                      goalAmount={campaginData.goalAmount} ></CampaignProgressBar>
                  </div>

                  <ActionControl2 campaginData={campaginData}></ActionControl2>
                </div>

              ))}
            </div>
          </div>

          {campaginRejectedData.length > 0 && <div className='discoverCard'>
            <Heading subtitle='' title='Closed Campaigns' />
            <div className='app-container grid2'>

              {campaginRejectedData.slice(0, 5).map((val) => (
                <div className='items shadow'>
                  <div className='content flex'>
                    <div className='left text'>
                      <div className="header">
                        <button class="btn-green">{val.category.toUpperCase()}</button>
                        <button class="btn-orange">CLOSED</button>
                      </div>
                      <h3> Closed - {val.campaignTitle}..</h3>
                      <br />
                      <div className='details'>
                        <div className='box'>
                          <div className='dimg1'>
                            <img src={val.image} alt='' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=''>
                    <CampaignProgressBar currentAmount={val.amount}
                      goalAmount={val.goalAmount} ></CampaignProgressBar>
                  </div>
                  <ActionControl2 type='view' campaginData={val}></ActionControl2>
                </div>
              ))}
            </div>
          </div>
          }
        </div>
      </section>
    </>
  )
}
export default DiscoverCard
