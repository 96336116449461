import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { UpdateCampaginSharedWhatsupData } from '../../../services/common-service';

const ActionControl = ({ status, setVisibleModal }) => {
  let URLPath = window.location.href;
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <div  className='action-control'>
        <Button variant="primary" size="lg" target="_blank"
          onClick={() => openInNewTab('https://api.whatsapp.com/send?text=/' + URLPath)}
        >Share on WhatsApp ! </Button>
        {' '}
        {status == 'Approved' && <Button variant="warning" href="#" size="lg"
          onClick={() => setVisibleModal(true)} >Donate Now !</Button>}
      </div>
    </>
  )
}


const ActionControl2 = ({ campaginData, type }) => {
  let URLPath = window.location.href + `/view-details/${campaginData.title}` ;
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
    UpdateCampaginSharedWhatsupData(campaginData);
  };

  return (
    <>
      <div className="action-control flexSB">
        <button className='primary-btn' onClick={() => openInNewTab('https://api.whatsapp.com/send?text=' + URLPath)}
        >SHARE ! </button>  
        <Link to={`/view-details/${campaginData.title}`}>
          <button className='secoundry-btn'  > { type == 'view' ? 'VIEW !' :  'SUPPORT !' } </button>
        </Link>
      </div>
    </>
  )
}


export { ActionControl, ActionControl2 };