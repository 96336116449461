import React from "react"
import Back from "../back/Back"
import FAQCard from "./faq-card"
import "./faq.css"
import Focus from "../focus/focus"

const FAQForm = () => {
    return (
        <>
            <Focus>
                <Back title='FAQ' />
                <FAQCard />
            </Focus>
        </>
    )
}

export default FAQForm
