import React from "react"
import FeedbackUsCard from "./feedback-card"
import ReactHelmet from "../../services/react-helmet"
import Focus from "../common/focus/focus"
import Back from "../common/back/Back"

const FeedbackUs = () => {
  return (
    <>
      <ReactHelmet 
      title = 'Feedback Us'
      desc = 'Feedback Us'
      url = 'feedback'/>

      <Focus>
        <Back type='back2' title='Feedback Us' />
        <FeedbackUsCard />
      </Focus>
    </>

  )
}

export default FeedbackUs
