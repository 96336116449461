import React, { useEffect, useState } from "react"
import Heading from "../common/heading/Heading"
import firebase from "../../firebase";
import { useParams } from "react-router-dom";
import { GetCampaginDataByTitle, UpdateCampaginStatusData } from "../../services/common-service";

const AdminViewDetails = () => {
  let { id } = useParams();
  const [campaginData, setCampaginData] = useState([]);
  const refCampaginTable = firebase.firestore().collection('campaginTable');
  const refCampaginImageTable = firebase.firestore().collection('CampaginImageTable');

  useEffect(() => { 

    const fetchData = async () => {
      const { campgainData } = await GetCampaginDataByTitle(id, refCampaginTable, refCampaginImageTable);
      setCampaginData(campgainData);
    };
    fetchData();
     
  }, []);
  const updateStatusOnClick = (status) => {
    UpdateCampaginStatusData(campaginData, status);
  };

  return (
    <>
      <section className='homeAbout'>
        <section className='back2'> </section>
        <div className='app-container'>
          <Heading subtitle='Support us' title='Urgent Help required to our campaign' />
          <div className='coursesCard'>
            <div className=''>
              <div className='items'>
                <div className='content flex'>
                  <div className='text'>
                    <h1><i className='fa fa-star'></i> {campaginData.campaignTitle} <i className='fa fa-star'></i></h1>
                    <br />
                    <div className='details'>
                      <label> ID : {campaginData.id} </label> <br />
                      <label> Country : {campaginData.country} </label> <br />
                      <label> Goal Amount : {campaginData.goalAmount} </label><br />
                      <label> Currency : {campaginData.currency} </label><br />
                      <label> Category : {campaginData.category} </label><br />
                      <label> Location : {campaginData.location} </label><br />

                      <label> End Date : {campaginData.endDate} </label><br />
                      <label> Type : {campaginData.type} </label><br />
                      <label> Campaigner Name : {campaginData.campaignerName} </label><br />
                      <label> Campaigner EmailId : {campaginData.campaignerEmailId} </label><br />
                      <label> Campaigner Phone : {campaginData.campaignerPhone} </label><br />

                      <label> Beneficiary Name : {campaginData.beneficiaryName} </label><br />
                      <label> Beneficiary EmailId : {campaginData.beneficiaryEmailId} </label><br />
                      <label> Beneficiary Phone : {campaginData.beneficiaryPhone} </label><br />
                      <label> Beneficiary DOB : {campaginData.beneficiaryDOB} </label><br />

                      <label> Beneficiary Address : {campaginData.beneficiaryAddress} </label><br />
                      <label> Beneficiary Address1 : {campaginData.beneficiaryAddress1} </label><br />
                      <label> Beneficiary Address2 : {campaginData.beneficiaryAddress2} </label><br />
                      <label> Beneficiary City : {campaginData.beneficiaryCity} </label><br />

                      <label> Beneficiary State : {campaginData.beneficiaryIdState} </label><br />
                      <label> Beneficiary BankName : {campaginData.beneficiaryBankName} </label><br />
                      <label> Beneficiary IFSCCode : {campaginData.beneficiaryIFSCCode} </label><br />
                      <label> Beneficiary AccountNo : {campaginData.beneficiaryAccountNo} </label><br />
                      <label> Account Holder Name : {campaginData.AccountHolderName} </label><br />

                      <label> Is Active : {campaginData.isActive} </label><br />
                      <label> Status : {campaginData?.status} </label><br /><br />
                      {/* <label> Created At : {campaginData?.createdAt} </label><br/> */}

                      <div className='box'>
                        <div className='dimg1'>
                          <img src={campaginData.image} alt='' />
                          <img src={campaginData.additionalImages} alt='' />
                          <img src={campaginData.campaignerIdProof} alt='' />
                          <img src={campaginData.beneficiaryIdProof} alt='' />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: campaginData.yourStory }} />


                <div className="flexSB">
                  <button className='primary-btn' onClick={() => updateStatusOnClick('Approved')}>Approve ! </button>
                  <button className='secoundry-btn' onClick={() => updateStatusOnClick('Rejected')}>Reject !</button>
                  <button className='secoundry-btn' onClick={() => updateStatusOnClick('Closed')}>Closed !</button>
                  <button className='secoundry-btn' onClick={() => updateStatusOnClick('Deleted')}>Deleted !</button>
                </div>
              </div>


            </div>
          </div>
        </div>
        {/* <OnlineCourses /> */}
      </section>
    </>
  )
}

export default AdminViewDetails
