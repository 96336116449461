import React, { useState } from 'react';

const Step5 = ({ nextStep, prevStep, handleChange, values }) => {
  const [errors, setErrors] = useState({});
  const handleSubmit = (e, nextStep, formData) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      nextStep();
    }
  };

  const validateForm = (data) => {
    const newErrors = [];
    if (!data.beneficiaryName.trim()) {
      newErrors.beneficiaryName = 'Beneficiary name is required';
    }  
    if (!data.beneficiaryEmailId.trim()) {
      newErrors.beneficiaryEmailId = 'Beneficiary Email Id is required';
    }   
    if (!data.beneficiaryPhone.trim()) {
      newErrors.beneficiaryPhone = 'Beneficiary phone is required';
    } 
    if (!data.beneficiaryDOB.trim()) {
      newErrors.beneficiaryDOB = 'Beneficiary DOB is required';
    } 
    if (!data.beneficiaryIdProof.trim()) {
      newErrors.beneficiaryIdProof = 'Beneficiary IdProof is required';
    } 
    if (!data.beneficiaryAddress.trim()) {
      newErrors.beneficiaryAddress = 'Beneficiary address is required';
    } 
    if (!data.beneficiaryAddress1.trim()) {
      newErrors.beneficiaryAddress1 = 'Beneficiary address1 is required';
    } 
    if (!data.beneficiaryCity.trim()) {
      newErrors.beneficiaryCity = 'Beneficiary City is required';
    } 
    if (!data.beneficiaryState.trim()) {
      newErrors.beneficiaryState = 'Beneficiary State is required';
    } 
    if (!data.beneficiaryBankName.trim()) {
      newErrors.beneficiaryBankName = 'Beneficiary Bank is required';
    } 
    if (!data.beneficiaryIFSCCode.trim()) {
      newErrors.beneficiaryIFSCCode = 'Beneficiary IFSCCode is required';
    } 
    if (!data.beneficiaryAccountNo.trim()) {
      newErrors.beneficiaryAccountNo = 'Beneficiary AccountNo is required';
    } 
    if (!data.beneficiaryAccountHolderName.trim()) {
      newErrors.beneficiaryAccountHolderName = 'Beneficiary Account HolderName is required';
    } 
    setErrors(newErrors);
    return newErrors;
  };

  
  const handleChange1 = (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0];
    const allowedFileTypes = ["jpg", "png"];
    const newErrors = [];
    const fileExtension = selectedFile?.name?.split(".").at(-1);

    if (name == "beneficiaryIdProof") {
      if (!allowedFileTypes.includes(fileExtension)) {
        newErrors.beneficiaryIdProof = "File does not support. You must use .png or .jpg";
      }
      else if (selectedFile.size > 85000) {
        newErrors.beneficiaryIdProof = "Please upload a file smaller than 85 KB";
      }
    }  

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleChange(e);
    }
  };

  return (
    <section tabindex="1" id="steps">

      <div class="stepper-wrapper">
        <div class="stepper-item completed">
          <div class="step-counter">1</div>
        </div>
        <div class="stepper-item completed">
          <div class="step-counter">2</div>
        </div>
        <div class="stepper-item completed">
          <div class="step-counter">3</div>
        </div>
        <div class="stepper-item completed">
          <div class="step-counter">4</div>
        </div>
        <div class="stepper-item active">
          <div class="step-counter">5</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter">6</div>
        </div>
      </div>

      <div class=''>
        <h2>Step 5: Beneficiary's Personal and Bank Information </h2>
        <br />
        <br />
        <div class="app-row ">
          <div class="col-75">
            <label>
            Beneficiary Name
              <input
                type="text"
                name="beneficiaryName"
                value={values.beneficiaryName}
                onChange={handleChange}
              />
              {errors.beneficiaryName && (
              <label className="error-message">
                {errors.beneficiaryName}
              </label>
            )}
            </label>
          </div>
        </div>
        <div class="app-row">
          <div class="col-75">
            <label>
            Beneficiary Email
              <input
                type="text"
                name="beneficiaryEmailId"
                value={values.beneficiaryEmailId}
                onChange={handleChange}
              />
               {errors.beneficiaryEmailId && (
              <label className="error-message">
                {errors.beneficiaryEmailId}
              </label>
            )}
            </label>
          </div>
        </div>


        <div class="app-row">
          <div class="col-75">
            <label>
            Beneficiary Phone
              <input
                type="text"
                name="beneficiaryPhone"
                value={values.beneficiaryPhone}
                onChange={handleChange}
              />
               {errors.beneficiaryPhone && (
               <label className="error-message">
                {errors.beneficiaryPhone}
              </label>
            )}
            </label>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <label>
              DOB:
              <input
                type="date"
                name="beneficiaryDOB"
                value={values.beneficiaryDOB}
                onChange={handleChange}
              />
               {errors.beneficiaryDOB && (
               <label className="error-message">
                {errors.beneficiaryDOB}
              </label>
            )}
            </label>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <label>
              Beneficiary Id Proof


              <div class="drop-zone">

                {values.beneficiaryIdProof ? <img src={values.beneficiaryIdProof} className="image" alt="preview" /> :
                  <span class="drop-zone__prompt">Drop file here or click to upload</span>}

                <input
                  type="file"
                  name="beneficiaryIdProof"
                  accept="image/*"
                  class="drop-zone__input"
                  onChange={handleChange1}></input>
              </div>
              {errors.beneficiaryIdProof && (
               <label className="error-message">
                {errors.beneficiaryIdProof}
              </label>
            )}
            </label>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <label>
              Address
              <input
                type="text"
                name="beneficiaryAddress"
                value={values.beneficiaryAddress}
                onChange={handleChange}
              />
               {errors.beneficiaryAddress && (
               <label className="error-message">
                {errors.beneficiaryAddress}
              </label>
            )}
            </label>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <label>
              Address Line 1
              <input
                type="text"
                name="beneficiaryAddress1"
                value={values.beneficiaryAddress1}
                onChange={handleChange}
              />
               {errors.beneficiaryAddress1 && (
               <label className="error-message">
                {errors.beneficiaryAddress1}
              </label>
            )}
            </label>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <label>
              Address Line 2
              <input
                type="text"
                name="beneficiaryAddress2"
                value={values.beneficiaryAddress2}
                onChange={handleChange}
              />
               
            </label>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <div class="col-45">
              <label>
                City
                <input
                  type="text"
                  name="beneficiaryCity"
                  value={values.beneficiaryCity}
                  onChange={handleChange}
                />
                 {errors.beneficiaryCity && (
               <label className="error-message">
                {errors.beneficiaryCity}
              </label>
            )}
              </label>
            </div>
            <div class="col-5"></div>
            <div class="col-45">
              <label>
                State / Province / Region
                <input
                  type="text"
                  name="beneficiaryState"
                  value={values.beneficiaryState}
                  onChange={handleChange}
                />
                  {errors.beneficiaryState && (
               <label className="error-message">
                {errors.beneficiaryState}
              </label>
            )}
              </label>
            </div>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <label>
              Bank Name
              <input
                type="text"
                name="beneficiaryBankName"
                value={values.beneficiaryBankName}
                onChange={handleChange}
              />
              {errors.beneficiaryBankName && (
               <label className="error-message">
                {errors.beneficiaryBankName}
              </label>
            )}
            </label>
          </div>
        </div>


        <div class="app-row">
          <div class="col-75">
            <label>
              IFSC Code
              <input
                type="text"
                name="beneficiaryIFSCCode"
                value={values.beneficiaryIFSCCode}
                onChange={handleChange}
              />
              {errors.beneficiaryIFSCCode && (
               <label className="error-message">
                {errors.beneficiaryIFSCCode}
              </label>
            )}
            </label>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <label>
              Account Number
              <input
                type="text"
                name="beneficiaryAccountNo"
                value={values.beneficiaryAccountNo}
                onChange={handleChange}
              />
               {errors.beneficiaryAccountNo && (
               <label className="error-message">
                {errors.beneficiaryAccountNo}
              </label>
            )}
            </label>
          </div>
        </div>

        <div class="app-row">
          <div class="col-75">
            <label>
              Account Holder Name
              <input
                type="text"
                name="beneficiaryAccountHolderName"
                value={values.beneficiaryAccountHolderName}
                onChange={handleChange}
              />
               {errors.beneficiaryAccountHolderName && (
               <label className="error-message">
                {errors.beneficiaryAccountHolderName}
              </label>
            )}
            </label>
          </div>
        </div>

        <br />
        <br />
        <button className='primary-btn' onClick={prevStep}>Back</button>
        <button className='secoundry-btn' onClick={(e) => handleSubmit(e, nextStep, values)}>Next</button>
      </div>

    </section>



  );
};

export default Step5;