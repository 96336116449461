import React from "react"
import Heading from "../common/heading/Heading"
import "./about.css"
import { homeAbout } from "../../data"
import Awrapper from "./Awrapper"

const AboutCard = () => {
  return (
    <>
      <section className='aboutHome padding'>
        <div className='app-container 
        '>
          <div className=''>
            <Heading subtitle='About Us' title='' />
            <div className='text'>
              <p>AJ Smile Foundation (AJ Educational & Charitable Trust) is a non-profit organization (Registration No: AAFTA2134CE20221) founded by a dedicated group committed to identifying and supporting individuals across India who are deprived of basic necessities.
              </p>
              <br />
              <p>Our mission extends beyond addressing immediate needs; we aim to empower these individuals through access to education, healthcare, women empower, employment opportunities, and essential services.</p>
              <br />
              <p>
              We believe that every person deserves not just the chance to survive but to thrive. Our goal is to uplift those in need, enabling them to pursue their dreams and build fulfilling, independent lives. We are driven by the vision of a world where every individual enjoys equal opportunities for growth, protection, and self-sufficiency. By fostering development and empowering communities, we strive to create lasting, positive change in society.</p>
            </div>

            <div className='items'>
              {homeAbout.map((val) => {
                return (
                  <div className='item flexSB'>
                    <div className='img '>
                      <img src={val.cover} alt='' /> &nbsp;&nbsp;
                    </div>
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <br/>
          </div>
        </div>
      </section>
    
    </>
  )
}

export default AboutCard
